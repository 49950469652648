import AuthRepository from "../../domain/auth/repository/AuthRepository";
import AuthService from "../../data/network-service/services/AuthService";
import DataStore from "../../data/data-store/DataStore";
import LocalDataStore from "../../data/data-store/LocalDataStore";
import StaffRepository from "../../domain/users/repository/StaffRepository";
import StaffService from "../../data/network-service/services/StaffService";
import BookingRepository from "../../domain/booking/repository/BookingRepository";
import BookingService from "../../data/network-service/services/BookingService";
import BuildingsRepository from "../../domain/buildings/repository/BuildingsRepository";
import BuildingsService from "../../data/network-service/services/BuildingsService";

class DependenciesContainer {
    dataStore: DataStore;
    authRepository: AuthRepository;
    staffRepository: StaffRepository;
    bookingRepository: BookingRepository;
    buildingsRepository: BuildingsRepository;

    constructor() {
        this.dataStore = new LocalDataStore();
        this.authRepository = new AuthService(this.dataStore);
        this.staffRepository = new StaffService(this.dataStore);
        this.bookingRepository = new BookingService(this.dataStore);
        this.buildingsRepository = new BuildingsService(this.dataStore);
    }
}

const Dependencies = new DependenciesContainer();

export default Dependencies;