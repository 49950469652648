import {
    Button, Card, Col, Row, Table, Typography, Spin,
    Select, Form, Input, Divider, List, Checkbox, Switch, Tag, Empty
} from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import React from "react";
import Audience from "../../../../../domain/buildings/models/Audience";
import Building from "../../../../../domain/buildings/models/Building";
import Notifications from "../../../../utils/Notifications";
import AudienceGroupsViewModel from "../view-models/AudienceGroupsViewModel";
import "./audience-groups.scss";
import {
    BookingAudienceGroup, BookingAudienceGroupBody,
    BookingFormField, BookingFormFieldBody
} from "../../../../../domain/booking/models/BookingAudienceGroup";
import { BookingRole, BookingRoleBody } from "../../../../../domain/booking/models/BookingRole";
import './selected-group.scss'
import SelectedGroupForm, { SelectedGroupProps } from "./components/SelectedGroupForm";
import MDEditor from "@uiw/react-md-editor";
import BookingRolesList from "../../../../components/BookingRolesList";

const { Title, Paragraph } = Typography;
const { Option } = Select;

interface AudienceGroupsProps {
    audienceViewModel: AudienceGroupsViewModel
}

interface AudienceGroupsViewState {
    bookingAudienceGroups: BookingAudienceGroup[];
    fiteredBookingAudienceGroups: BookingAudienceGroup[];
    selectedGroup?: BookingAudienceGroup;
    buildings: Building[];
    audiences: Audience[];
    audienceFilter: string;
    selectedAudiences: Audience[];
    bookingRoles: BookingRole[];
    bookingRolesFilter: string;
    selectedBookingRoles: BookingRole[];
    formFieldTypes: string[],
    emailPattern: string | null;
    isLoading: boolean;
    isNotifyAboutNewBookingsRequested: boolean;
    userEmail?: string;
    isDeletePopoverVisible: boolean;
    shouldAddNewAudienceGroup: boolean;
}

export default class AudienceGroupsView extends React.Component<AudienceGroupsProps, AudienceGroupsViewState>{
    private audienceViewModel: AudienceGroupsViewModel;

    constructor(props: AudienceGroupsProps) {
        super(props);
        this.state = {
            bookingAudienceGroups: [],
            fiteredBookingAudienceGroups: [],
            selectedGroup: undefined,
            buildings: [],
            audiences: [],
            audienceFilter: '',
            selectedAudiences: [],
            bookingRoles: [],
            bookingRolesFilter: '',
            formFieldTypes: [],
            selectedBookingRoles: [],
            emailPattern: null,
            isLoading: false,
            isNotifyAboutNewBookingsRequested: false,
            userEmail: '',
            isDeletePopoverVisible: false,
            shouldAddNewAudienceGroup: false
        };
        this.audienceViewModel = props.audienceViewModel;
    }

    componentDidMount() {
        this.bindToViewModel();
        this.audienceViewModel.initViewModel()
    }

    private bindToViewModel() {
        this.audienceViewModel.onDidStartRequest = () => {
            this.setState({
                isLoading: true
            });
        };
        this.audienceViewModel.onDidFinishRequest = () => {
            this.setState({
                isLoading: false
            });
        };
        this.audienceViewModel.onDidUpdate = () => {
            this.setState({
                bookingAudienceGroups: this.audienceViewModel.bookingAudienceGroups,
                fiteredBookingAudienceGroups: this.audienceViewModel.bookingAudienceGroups,
                buildings: this.audienceViewModel.buildings,
                audiences: this.audienceViewModel.audiences,
                bookingRoles: this.audienceViewModel.bookingRoles,
                formFieldTypes: this.audienceViewModel.bookingFormFieldsTypes,
                userEmail: this.audienceViewModel.userEmail,
                selectedGroup: this.audienceViewModel.bookingAudienceGroups.find(group => {
                    return group.id === this.audienceViewModel.newCreatedAudienceGroupId ||
                        group.id === this.state.selectedGroup?.id
                })
            });
        };
        this.audienceViewModel.onDidReceiveError = (error) => {
            Notifications.handle(error);
            this.setState({
                isLoading: false
            });
        };
    }

    postNewGroupHandler(values: any) {

        const newBookingAudienceGroup: BookingAudienceGroupBody = {
            name: values.newGroupName,
            isNotifyAboutNewBookingsRequested: this.state.isNotifyAboutNewBookingsRequested,
            emailTemplate: this.state.emailPattern
        }

        let roleIds: string[] = []
        this.state.selectedBookingRoles.forEach(role => {
            roleIds.push(role.id)
        })


        let audienceIds: string[] = []
        this.state.selectedAudiences.forEach(audience => {
            audienceIds.push(audience.id)
        })

        let fields: BookingFormFieldBody[] = []
        values.AdditionalFields?.forEach((field: BookingFormFieldBody) => {
            fields.push(field)
        })

        this.audienceViewModel.createBookingAudienceGroup(newBookingAudienceGroup, audienceIds, roleIds, fields)
    }

    updateBookingAudienceGroup(audienceGroup: BookingAudienceGroupBody) {
        this.audienceViewModel.updateBookingAudienceGroup(this.state.selectedGroup!.id, audienceGroup)
    }

    deleteBookingAudienceGroup(audienceGroupId: string) {
        this.audienceViewModel.deleteBookingAudienceGroup(audienceGroupId);
        this.setState({ selectedGroup: undefined })
    }

    bookingRolesCheckboxChanged(e: any, role: BookingRole) {
        if (e.target.checked) {
            this.setState({ selectedBookingRoles: [...this.state.selectedBookingRoles, role] })
        }
        else {
            this.setState({ selectedBookingRoles: this.state.selectedBookingRoles.filter(val => val.id !== role.id) })
        }
    }

    createBookingRole(value: string, e: any) {
        e.preventDefault()
        const newBookingRoleBody: BookingRoleBody = { name: value, email: this.state.userEmail }
        this.audienceViewModel.createBookingRole(newBookingRoleBody)
    }

    bookingRolesFilterChanged(changeEvent: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ bookingRolesFilter: changeEvent.currentTarget.value })
    }

    audienceCheckBoxChanged(e: any, audience: Audience) {
        if (e.target.checked) {
            this.setState({ selectedAudiences: [...this.state.selectedAudiences, audience] })
        }
        else {
            this.setState({ selectedAudiences: this.state.selectedAudiences.filter(val => val.id !== audience.id) })
        }
    }

    buildingChangedHandler(selectedBuilding: string) {
        this.audienceViewModel.getAudiences(selectedBuilding);
    };

    audienceFilterChanged(changeEvent: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ audienceFilter: changeEvent.currentTarget.value })
    }

    filterOptionForSelector(input: string, option: DefaultOptionType | undefined): boolean {
        return (option!.children as unknown as string).toLowerCase().includes(input.trim().toLowerCase())
    }

    notifyAboutNewBookingsRequestedIsChanged() {
        this.setState({ isNotifyAboutNewBookingsRequested: !this.state.isNotifyAboutNewBookingsRequested })
    }

    setEmailPattern(value: string) {
        this.setState({ emailPattern: value })
    }

    getFilteredArray(array: Audience[] | BookingRole[], input: string): Audience[] | BookingRole[] {
        return array?.filter(element => element.name.toLowerCase().includes(input.trim().toLowerCase()) === true)
    }

    hideDeletePopover() {
        this.setState({ isDeletePopoverVisible: false })
    };

    deletePopoverOpenChange(newOpen: boolean) {
        this.setState({ isDeletePopoverVisible: newOpen })
    };

    deleteRoleFromGroup(ownerId: string, roleId: string) {
        this.audienceViewModel.deleteRoleFromGroup(this.state.selectedGroup!.id, roleId)
    }

    addRoleInGroup(ownerId: string, roleId: string) {
        this.audienceViewModel.addRoleInGroup(ownerId, roleId)
    }

    deleteAudienceFromGroup(audienceId: string) {
        this.audienceViewModel.deleteAudienceFromGroup(this.state.selectedGroup!.id, audienceId)
    }

    addAudienceInGroup(audienceId: string) {
        this.audienceViewModel.addAudienceInGroup(this.state.selectedGroup!.id, audienceId)
    }

    updateFormFieldInGroup(formField: BookingFormField) {
        const newFormField: BookingFormFieldBody = formField;
        this.audienceViewModel.updateFormFieldInGroup(this.state.selectedGroup!.id, formField.id, newFormField)
    }
    deleteFormFieldFromGroup(formField: BookingFormField) {
        this.audienceViewModel.deleteFormFieldFromGroup(this.state.selectedGroup!.id, formField.id)
    }

    addFormFieldInGroup(formField: BookingFormFieldBody) {
        this.audienceViewModel.addFormFieldInGroup(this.state.selectedGroup!.id, formField)
    }

    headerButtonslayout() {
        return (
            <Form.Item>
                <div className="buttons-wrapper">
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                            this.state.selectedBookingRoles.length <= 0 ||
                            this.state.selectedAudiences.length <= 0
                        }
                    >
                        Отправить
                    </Button>
                </div>

            </Form.Item>
        )
    }

    nameAndEmailTemplateLayout() {
        return (
            <>
                <Form.Item
                    name="newGroupName"
                    rules={[{ required: true, message: '' }]}
                >
                    <Input placeholder="Название группы" />
                </Form.Item>

                <Form.Item className="message-form">
                    <div className="message-form-switch-wrapper">
                        <Switch onChange={() => this.notifyAboutNewBookingsRequestedIsChanged()} />
                        <span>
                            Отправлять письмо при создании заявки
                        </span>
                    </div>

                    <div className="message-form-redactor" hidden={!this.state.isNotifyAboutNewBookingsRequested}>
                        <MDEditor
                            data-color-mode="light"
                            value={this.state.emailPattern!}
                            onChange={(value) => this.setEmailPattern(value!)}
                        />
                    </div>
                </Form.Item>
            </>
        )
    }

    audiencesListLayout() {


        let otherGroups = this.audienceViewModel.bookingAudienceGroups.filter(
            group => group.id !== '00000000-0000-0000-0000-000000000000'
        )
        let audsOfOtherGroups: Audience[] = []
        otherGroups.map(group => audsOfOtherGroups.push(...group.audiences))
        const audiencesWithoutGroup = this.state.audiences.filter(aud => !audsOfOtherGroups.some(x => x.id === aud.id))

        return (
            <Row gutter={16} className="row-wrapper">
                <Col span={10} >
                    <div className="list-wrapper">
                        <List
                            size="small"
                            header={
                                <div className="list-header">
                                    <Typography.Paragraph>Список аудиторий: </Typography.Paragraph>
                                </div>
                            }
                            bordered
                            dataSource={this.state.selectedAudiences}
                            renderItem={
                                item =>
                                    <List.Item
                                        extra={
                                            <div className="delete-field-button-wrapper">
                                                <CloseCircleOutlined
                                                    checked={false}
                                                    onClick={(e) => { this.audienceCheckBoxChanged(e, item) }}
                                                />
                                            </div>
                                        }
                                    >
                                        <div className="content-wrapper">{item.name}</div>
                                    </List.Item>
                            }
                        />
                    </div>

                </Col>

                <Col span={14}>

                    <Form.Item>
                        <Select
                            showSearch
                            className="defaultSelector"
                            placeholder="Номер корпуса"
                            optionFilterProp="children"
                            onChange={(value) => this.buildingChangedHandler(value)}
                            filterOption={(input, option) => this.filterOptionForSelector(input, option)}
                        >
                            {
                                this.state.buildings.map(building => {
                                    return <Option key={building.id} value={building.id}>{building.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Input
                            placeholder='Поиск'
                            prefix={<SearchOutlined />}
                            onChange={(changeEvent) => this.audienceFilterChanged(changeEvent)}
                        />
                    </Form.Item>

                    {
                        this.state.selectedAudiences.length === audiencesWithoutGroup.length &&
                            audiencesWithoutGroup.length !== 0 ?

                            <Button
                                className="select-all-audiences-button"
                                disabled={audiencesWithoutGroup.length === 0}
                                onClick={() => this.setState({ selectedAudiences: [] })}
                                size="small"
                                danger
                                block
                            >
                                Снять выделение со всех аудиторий
                            </Button>
                            :
                            <Button
                                className="select-all-audiences-button"
                                disabled={audiencesWithoutGroup.length === 0}
                                onClick={() => this.setState({ selectedAudiences: audiencesWithoutGroup })}
                                size="small"
                                type="primary"
                                block
                            >
                                Выделить все аудитории
                            </Button>
                    }


                    <div className="list-wrapper">
                        <List
                            size="small"
                            bordered
                            dataSource={this.getFilteredArray(audiencesWithoutGroup!, this.state.audienceFilter)}
                            renderItem={
                                item =>
                                    <List.Item
                                    >
                                        <Checkbox
                                            checked={this.state.selectedAudiences.some(selectedVal => selectedVal.id === item.id)}
                                            onChange={(e) => { this.audienceCheckBoxChanged(e, item) }}
                                        > {
                                                item.name}
                                        </Checkbox>
                                    </List.Item>
                            }
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    additionalListLayout() {

        return (
            <div className="additional-fields">

                <Form.List name="AdditionalFields">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (

                                <div className="additional-filed-input-wrapper">
                                    <div className="additional-filed-input-wrapper-formItems">
                                        <div className="form-items-row">
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'title']}
                                                rules={[{ required: true, message: '' }]}
                                            >
                                                <Input placeholder="название поля" />
                                            </Form.Item>


                                            <Form.Item
                                                {...restField}
                                                name={[name, 'templateId']}
                                                rules={[{ required: true, message: '' }]}
                                            >
                                                <Input placeholder="название переменной" />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[name, 'type']}
                                                rules={[{ required: true, message: '' }]}
                                            >
                                                <Select
                                                    className="form-items-row-type-selector"
                                                    defaultValue={"тип данных"}
                                                    options={this.state.formFieldTypes.map(type => {
                                                        return { value: type, label: type }
                                                    })}

                                                />
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            {...restField}
                                            className="form-items-checkbox-wrapper"
                                            name={[name, 'isOptional']}
                                            initialValue={false}
                                            valuePropName="checked"
                                        >
                                            <Checkbox value={false}>Поле опционально для заполнения?</Checkbox>
                                        </Form.Item>


                                    </div>
                                    <div className="delete-field-button-wrapper">
                                        <CloseCircleOutlined onClick={() => remove(name)} />
                                    </div>
                                </div>
                            ))}
                            <Form.Item>
                                <div className="add-field-button-wrapper">
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Добавить поле
                                    </Button>
                                </div>

                            </Form.Item>


                        </>
                    )}
                </Form.List>

            </div>
        )
    }

    newGroupForm() {

        return (

            <Form onFinish={(values) => this.postNewGroupHandler(values)} name="newGroup">
                {this.headerButtonslayout()}
                {this.nameAndEmailTemplateLayout()}
                <Divider />
                <BookingRolesList
                    selectedBookingRoles={this.state.selectedBookingRoles}
                    bookingRoles={this.state.bookingRoles}
                    createBookingRole={this.createBookingRole.bind(this)}
                    bookingRolesCheckboxChanged={this.bookingRolesCheckboxChanged.bind(this)}
                />
                <Divider />
                {this.audiencesListLayout()}
                <Divider />
                {this.additionalListLayout()}
            </Form>
        )
    }

    render() {


        const selectedGroupProps: SelectedGroupProps = {
            selectedGroup: this.state.selectedGroup,
            buildings: this.state.buildings,
            audiences: this.state.audiences,
            audienceFilter: this.state.audienceFilter,
            bookingRoles: this.state.bookingRoles,
            bookingRolesFilter: this.state.bookingRolesFilter,
            isNotifyAboutNewBookingsRequested: this.state.isNotifyAboutNewBookingsRequested,
            isDeletePopoverVisible: this.state.isDeletePopoverVisible,
            formFieldTypes: this.state.formFieldTypes,
            bookingAudienceGroups: this.state.bookingAudienceGroups,

            deleteBookingAudienceGroup: this.deleteBookingAudienceGroup.bind(this),
            createBookingRole: this.createBookingRole.bind(this),
            bookingRolesFilterChanged: this.bookingRolesFilterChanged.bind(this),
            getFilteredArray: this.getFilteredArray.bind(this),
            buildingChangedHandler: this.buildingChangedHandler.bind(this),
            filterOptionForSelector: this.filterOptionForSelector.bind(this),
            audienceFilterChanged: this.audienceFilterChanged.bind(this),
            deletePopoverOpenChange: this.deletePopoverOpenChange.bind(this),
            hideDeletePopover: this.hideDeletePopover.bind(this),
            updateBookingAudienceGroup: this.updateBookingAudienceGroup.bind(this),
            deleteRoleFromGroup: this.deleteRoleFromGroup.bind(this),
            addRoleInGroup: this.addRoleInGroup.bind(this),
            deleteAudienceFromGroup: this.deleteAudienceFromGroup.bind(this),
            addAudienceInGroup: this.addAudienceInGroup.bind(this),
            updateFormFieldInGroup: this.updateFormFieldInGroup.bind(this),
            deleteFormFieldFromGroup: this.deleteFormFieldFromGroup.bind(this),
            addFormFieldInGroup: this.addFormFieldInGroup.bind(this)
        }

        return (
            <div className="audience-groups-page-container">
                <Title>Группы аудиторий</Title>

                <Card  >
                    {/* @ts-ignore */}
                    <Spin tip="Загрузка..." spinning={this.state.isLoading}>
                        <div className="header-wrapper">
                            <Input.Search
                                className="search-input"
                                placeholder="Название"
                                onSearch={(value: string) => {
                                    this.setState({
                                        fiteredBookingAudienceGroups: this.state.bookingAudienceGroups.filter((record) => {
                                            return record.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                        })
                                    });
                                }}
                                enterButton
                            />
                            <Button
                                type={'primary'}
                                onClick={() => this.setState({ selectedGroup: undefined, shouldAddNewAudienceGroup: true })}
                            >
                                Добавить группу
                            </Button>
                        </div>


                        <Row gutter={16} className="audience-groups-content-container">
                            <Col span={7}>

                                <Card
                                    bodyStyle={{ padding: 0 }}
                                >
                                    <Table
                                        showHeader={false}
                                        dataSource={this.state.fiteredBookingAudienceGroups}
                                        rowKey={record => record.id}
                                        pagination={{
                                            size: "small"
                                        }}>
                                        <Table.Column
                                            dataIndex="title"
                                            render={(text, record: BookingAudienceGroup) => {
                                                return (
                                                    <div className="audience-groups-list-content"
                                                        onClick={() => this.setState({ selectedGroup: record })}>
                                                        <Paragraph ellipsis={{
                                                            rows: 1,
                                                        }} className="title">{record.name}</Paragraph>

                                                        <div
                                                            style={{ marginTop: '16px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}
                                                        >
                                                            {record.requiredRoles.map(role => {
                                                                return <Tag key={role.id}>{role.name}</Tag>
                                                            })}
                                                        </div>
                                                    </div>
                                                );
                                            }} />
                                    </Table>
                                </Card>
                            </Col>
                            <Col span={17}>
                                <Card>
                                    {
                                        this.state.selectedGroup ? <SelectedGroupForm {...selectedGroupProps} /> :
                                            this.state.shouldAddNewAudienceGroup ? this.newGroupForm() : <Empty />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Spin>
                </Card>
            </div >

        )
    }
}