import AuthRepository from "../repository/AuthRepository";

export default class LogoutUseCase {
    private authRepository: AuthRepository;

    constructor(authRepository: AuthRepository) {
        this.authRepository = authRepository;
    }

    logout() {
        this.authRepository.logout();
    }
}