import React from "react";
import {Card, Layout, Form, Button, Input, Spin} from "antd";
import "./login.scss";
import { ReactComponent as TsuLogo } from "../../../../resources/images/tsu_logo_small.svg";
import {MailOutlined, LockOutlined} from "@ant-design/icons";
import Constants from "../../../../common/Constants";
import LoginStrings from "../../../../resources/strings/login";
import LoginViewModel from "../view-models/LoginViewModel";
import Notifications from "../../../../utils/Notifications";

interface LoginProps {
    viewModel: LoginViewModel;
}

interface LoginState {
    isLoading: boolean;
}

interface LoginFormValues {
    email: string;
    password: string;
}

export default class Login extends React.Component<LoginProps, LoginState> {
    private viewModel: LoginViewModel;

    constructor(props: LoginProps) {
        super(props);
        this.viewModel = this.props.viewModel;
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        this.bindToViewModel();
    }

    private bindToViewModel() {
        this.viewModel.onDidStartRequest = () => {
            this.setState({
                isLoading: true
            });
        };
        this.viewModel.onDidFinishRequest = () => {
            this.setState({
                isLoading: false
            });
        };
        this.viewModel.onDidReceiveError = (error) => {
            Notifications.handle(error);
        };
    }

    private loginWithTsuAccount = () => {
        window.location.href = Constants.tsuAccountsLoginUrl;
    }

    private loginWithEmail = (values: LoginFormValues) => {
        this.viewModel.loginWithEmail(values.email, values.password);
    }

    render() {
        return (
            <Layout>
                <div className="login-content">
                    <Card className="login-card">
                        {/* @ts-ignore */}
                        <Spin tip="Загрузка..." spinning={this.state.isLoading}>
                            <div className="login-logo">
                                <TsuLogo className="image"/>
                                <div className="title-container">
                                    <span className="title">{ LoginStrings.logoTitle }</span>
                                    <span className="subtitle">{ LoginStrings.logoSubtitle }</span>
                                </div>
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={this.loginWithEmail}
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Поле не может быть пустм' },
                                        { type: 'email', message: 'Введите корректный e-mail' }
                                    ]}
                                >
                                    <Input prefix={<MailOutlined className="site-form-item-icon"/>}
                                           placeholder={ LoginStrings.emailFieldPlaceholder } />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Поле не может быть пустым' }]}
                                >
                                    <Input
                                        prefix={<LockOutlined className="site-form-item-icon"/>}
                                        type="password"
                                        placeholder={LoginStrings.passwordFieldPlaceholder}
                                    />
                                </Form.Item>
                                <Form.Item className="small-margin-bottom">
                                    <Button type="primary" htmlType="submit" className="login-form-button">
                                        { LoginStrings.loginButtonTitle }
                                    </Button>
                                </Form.Item>
                                <Form.Item className="small-margin-bottom buttons-divider">
                                    { LoginStrings.buttonDividerText }
                                </Form.Item>
                                <Form.Item className="login-form-last-item">
                                    <Button type="default"
                                            className="login-form-button tsu-accounts-login-button"
                                            onClick={this.loginWithTsuAccount}>
                                        <TsuLogo className="icon"/>
                                        { LoginStrings.tsuAccountsLoginButtonTitle }
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </Card>
                </div>
            </Layout>
        );
    }
}