import NetworkService from "../NetworkService";
import HTTPMethod from "../HTTPMethod";
import AuthRepository from "../../../domain/auth/repository/AuthRepository";
import AuthResponse from "../../../domain/auth/models/AuthResponse";

export default class AuthService extends NetworkService implements AuthRepository {
    loginWithEmail(email: string, password: string): Promise<AuthResponse> {
        return this.request<AuthResponse>("/auth/login/email", HTTPMethod.post, {
           email: email,
           password: password
        }).then(response => {
            this.handleAuth(response);
            return Promise.resolve(response)
        });
    }

    loginWithTsuAccount(token: string): Promise<AuthResponse> {
        return this.request<AuthResponse>("/auth/login/tsu-account", HTTPMethod.post, {
            token: token
        }).then(response => {
            this.handleAuth(response);
            return Promise.resolve(response)
        });
    }

    logout() {
        this.dataStore.accessToken = null;
        this.dataStore.refreshToken = null;
    }

    private handleAuth(response: AuthResponse) {
        this.dataStore.accessToken = response.token.accessToken;
        this.dataStore.refreshToken = response.token.refreshToken;
        this.dataStore.userProfile = response.profile;
    }
}