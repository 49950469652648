import NetworkService from "../NetworkService";
import HTTPMethod from "../HTTPMethod";
import BookingRepository from "../../../domain/booking/repository/BookingRepository";
import BookingApplication from "../../../domain/booking/models/BookingApplication";
import EmptyResponse from "../EmptyResponse";
import { BookingAudienceGroup, BookingFormFieldBody, BookingAudienceGroupBody } from "../../../domain/booking/models/BookingAudienceGroup";
import { BookingRole, BookingRoleBody } from "../../../domain/booking/models/BookingRole";
import UserProfile from "../../../domain/common/models/UserProfile";

export default class BookingService extends NetworkService implements BookingRepository {
    getBookingApplications(): Promise<BookingApplication[]> {
        return this.request("/bookings", HTTPMethod.get)
    }

    approveBooking(bookingID: string): Promise<EmptyResponse> {
        return this.request(`/bookings/${bookingID}/approve`, HTTPMethod.post);
    }

    rejectBooking(bookingID: string, reason: string): Promise<EmptyResponse> {
        return this.request(`/bookings/${bookingID}/reject`, HTTPMethod.post, {
            comment: reason
        });
    }

    getBookingRoles(): Promise<BookingRole[]> {
        return this.request("/bookings/roles", HTTPMethod.get)
    }

    createBookingRole(newBookingRole: BookingRoleBody): Promise<EmptyResponse> {
        return this.request("/bookings/roles", HTTPMethod.post, newBookingRole);
    }

    updateBookingRole(roleId: string, newBookingRole: BookingRoleBody): Promise<EmptyResponse> {
        return this.request(`/bookings/roles/${roleId}`, HTTPMethod.put, newBookingRole);
    }

    deleteBookingRole(bookingRoleId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/roles/${bookingRoleId}`, HTTPMethod.delete);
    }

    addRoleInGroup(groupId: string, roleId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/roles`, HTTPMethod.post, { roleId: roleId });
    }

    deleteRoleFromGroup(groupId: string, roleId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/roles/${roleId}`, HTTPMethod.delete);
    }

    getBookingFormFieldsTypes(): Promise<string[]> {
        return this.request("/bookings/form/fields/types", HTTPMethod.get)
    }

    addFormFieldInGroup(groupId: string, formField: BookingFormFieldBody): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/fields`, HTTPMethod.post, formField);
    }

    updateFormFieldInGroup(groupId: string, formFieldId: string, formField: BookingFormFieldBody): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/fields/${formFieldId}`, HTTPMethod.put, formField);
    }

    deleteFormFieldFromGroup(groupId: string, formFieldId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/fields/${formFieldId}`, HTTPMethod.delete);
    }

    getBookingGroups(): Promise<BookingAudienceGroup[]> {
        return this.request("/bookings/groups", HTTPMethod.get)
    }

    createBookingAudienceGroup(newBookingAudienceGroup: BookingAudienceGroupBody): Promise<BookingAudienceGroup> {
        return this.request("/bookings/groups", HTTPMethod.post, newBookingAudienceGroup);
    }

    updateBookingAudienceGroup(groupId: string, newBookingAudienceGroup: BookingAudienceGroupBody): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}`, HTTPMethod.put, newBookingAudienceGroup);
    }

    deleteBookingAudienceGroup(audienceGroupId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${audienceGroupId}`, HTTPMethod.delete);
    }

    addAudienceInGroup(groupId: string, audienceId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/audiences`, HTTPMethod.post, { audienceId: audienceId });
    }

    deleteAudienceFromGroup(groupId: string, audienceId: string): Promise<EmptyResponse> {
        return this.request(`/bookings/groups/${groupId}/audiences/${audienceId}`, HTTPMethod.delete);
    }

    getCurrentUser(): Promise<UserProfile> {
        return this.request('/user/profile', HTTPMethod.get);
    }
}