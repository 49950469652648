import Audience from "../../../domain/buildings/models/Audience";
import Building from "../../../domain/buildings/models/Building";
import BuildingsRepository from "../../../domain/buildings/repository/BuildingsRepository";
import NetworkConstants from "../../common/NetworkConstants";
import HTTPMethod from "../HTTPMethod";
import NetworkService from "../NetworkService";

export default class BuildingsService extends NetworkService implements BuildingsRepository {

    getBuildings(): Promise<Building[]> { // базовый URL привязан к /api/admin/v1
        return this.request(`${NetworkConstants.apiWebURL}/buildings`, HTTPMethod.get)
    }

    getAudiences(selectedBuildingId: string): Promise<Audience[]> {
        return this.request(`${NetworkConstants.apiWebURL}/buildings/${selectedBuildingId}/audiences`, HTTPMethod.get)
    }
}