import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import NetworkConstants from "../common/NetworkConstants";
import HTTPMethod from "./HTTPMethod";
import DataStore from "../data-store/DataStore";
import AuthTokenInfo from "../../domain/auth/models/AuthTokenInfo";

export default class NetworkService {
    private instance: AxiosInstance;

    protected dataStore: DataStore;

    constructor(dataStore: DataStore) {
        this.instance = axios.create({
            baseURL: NetworkConstants.apiBaseURL
        });
        this.dataStore = dataStore;
        this.setupInterceptors();
    }

    request<T>(url: string, method: HTTPMethod, parameters: Object | null = null): Promise<T> {
        let config: AxiosRequestConfig = {
            url: url,
            method: method
        };

        if (method === HTTPMethod.get) {
            config.params = parameters;
        } else {
            config.data = parameters;
        }

        return this.instance.request(config).then(response => {
           return Promise.resolve(response.data);
        }).catch(error => {
            let customError = this.createError(error.response);
            return Promise.reject(customError);
        });
    }

    private createError(response: any): Error {
        if(response.data.code === "BookingFailure") {
            return new Error(response.data.message);
        } else {
            return new Error(response.data.code);
        }
    }

    private setupInterceptors() {
        this.setupRequestInterceptor();
        this.setupResponseInterceptor();
    }

    private setupRequestInterceptor() {
        this.instance.interceptors.request.use(config => {
            if (this.dataStore.accessToken) {
                if (!config) {
                    config = {};
                }
                if (!config.headers) {
                    config.headers = {};
                }
                config.headers.Authorization = `Bearer ${this.dataStore.accessToken}`;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
    }

    private setupResponseInterceptor() {
        let interceptor = this.instance.interceptors.response.use(response => {
            return response;
        }, error => {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                this.instance.interceptors.response.eject(interceptor);
                return this.refreshToken().then(token => {
                    this.dataStore.accessToken = token.accessToken;
                    this.dataStore.refreshToken = token.refreshToken;
                    return this.instance(originalRequest);
                }).catch(error => {
                    this.clearTokens();
                    return Promise.reject(error)
                }).finally(() => {
                    this.setupResponseInterceptor();
                });

            }
            return Promise.reject(error);
        });
    }

    private async refreshToken(): Promise<AuthTokenInfo> {
        return this.request<AuthTokenInfo>("/auth/refresh", HTTPMethod.post, {
            refreshToken: this.dataStore.refreshToken
        });
    }

    private clearTokens() {
        this.dataStore.accessToken = null;
        this.dataStore.refreshToken = null;
        window.location.href = "/";
    }
}