import React from "react";
import { AutoComplete, Card, Button, Table, Typography, Spin, Popover, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./users-view.scss";
import UsersViewModel from "../view-models/UsersViewModel";
import StaffInfo from "../../../../domain/users/models/StaffInfo";
import Notifications from "../../../utils/Notifications";
import { BookingRole, BookingRoleBody } from "../../../../domain/booking/models/BookingRole";
import BookingRolesList from "../../../components/BookingRolesList";
import UserProfile from "../../../../domain/common/models/UserProfile";
import EditableBookingRolesList from "../../../components/EditableBookingRolesList";

const { Title } = Typography;

interface UsersViewProps {
	viewModel: UsersViewModel;
}

interface UsersViewState {
	staff: StaffInfo[];
	bookingAdminsUserProfiles: UserProfile[];
	selectedUser?: string;
	isLoading: boolean;
	bookngRoles: BookingRole[];
	selectedBookingRoles: BookingRole[];
	userEmail?: string;
	userId: string;
	isDeleteAllRolesPopoverOpen: boolean;
	isAddNewAdminPopoverOpen: boolean;
}

export default class UsersView extends React.Component<UsersViewProps, UsersViewState> {
	private viewModel: UsersViewModel;

	constructor(props: UsersViewProps) {
		super(props);
		this.state = {
			staff: [],
			bookingAdminsUserProfiles: [],
			isLoading: false,
			bookngRoles: [],
			selectedBookingRoles: [],
			userEmail: "",
			userId: "",
			isDeleteAllRolesPopoverOpen: false,
			isAddNewAdminPopoverOpen: false
		};
		this.viewModel = props.viewModel;
	}

	private bindToViewModel() {
		this.viewModel.onDidStartRequest = () => {
			this.setState({
				isLoading: true,
			});
		};
		this.viewModel.onDidFinishRequest = () => {
			this.setState({
				isLoading: false,
			});
		};
		this.viewModel.onDidUpdate = () => {
			this.setState({
				staff: this.viewModel.staff,
				bookingAdminsUserProfiles: this.viewModel.bookingAdminsUserProfiles,
				bookngRoles: this.viewModel.bookingRoles,
				userEmail: this.viewModel.userEmail
			});
		};
		this.viewModel.onDidReceiveError = (error) => {
			Notifications.handle(error);
		};
	}

	componentDidMount() {
		this.bindToViewModel();
		this.viewModel.loadData();
	}

	createBookingRole(value: string, e: any) {
		e.preventDefault();
		const newBookingRoleBody: BookingRoleBody = {
			name: value,
			email: this.state.userEmail,
		};
		this.viewModel.createBookingRole(newBookingRoleBody);
	}

	bookingRolesCheckboxChanged(e: any, role: BookingRole) {
		if (e.target.checked) {
			this.setState({
				selectedBookingRoles: [...this.state.selectedBookingRoles, role],
			});
		} else {
			this.setState({
				selectedBookingRoles: this.state.selectedBookingRoles.filter((val) => val.id !== role.id),
			});
		}
	}

	changeRoleHandler(e: any, ownerId: string, role: BookingRole) {
		if (e.target.checked) {
			this.viewModel.addBookingRoleForModerator(ownerId, role.id);
		} else {
			this.viewModel.removeBookingRoleForModerator(ownerId, role.id);
		}
	}

	getBookingAdmin(record: StaffInfo) {
		return this.state.bookingAdminsUserProfiles.find((value) => {
			return value.tsuAccountProfile.accountId === record.accountId;
		});
	}

	showModal(userId: string) {
		this.setState({ userId: userId });
	}

	handleCancel() {
		this.setState({ userId: "" });
	}

	render() {
		return (
			<div className="users-page-container">
				<Title>Модераторы</Title>
				<Card className="users-content-container">
					{/* @ts-ignore */}
					<Spin tip="Загрузка..." spinning={this.state.isLoading}>
						<div className="search-container">
							<AutoComplete
								className="search-input"
								filterOption
								placeholder="Поиск по ФИО сотрудника"
								onSelect={(value: string, option: any) => {
									this.setState({
										selectedUser: option.key,
									});
								}}
							>
								{this.state.staff.map((item) => {
									return (
										<AutoComplete.Option key={item.accountId} value={item.name}>
											{item.name}
										</AutoComplete.Option>
									);
								})}
							</AutoComplete>
							<Popover
								trigger="click"
								placement="bottom"
								title="Выберите роли"
								open={this.state.isAddNewAdminPopoverOpen}
								onOpenChange={(visible) => this.setState({ isAddNewAdminPopoverOpen: visible })}
								content={
									<div style={{ width: '700px' }}>
										<BookingRolesList
											selectedBookingRoles={this.state.selectedBookingRoles}
											bookingRoles={this.state.bookngRoles}
											createBookingRole={this.createBookingRole.bind(this)}
											bookingRolesCheckboxChanged={this.bookingRolesCheckboxChanged.bind(this)}
										/>
										<Button
											type="primary"
											disabled={this.state.selectedBookingRoles.length === 0}
											style={{ marginTop: "16px" }}
											block
											onClick={() => {
												this.viewModel.addBookingAdmin(
													this.state.selectedBookingRoles.map((role) => {
														return role.id;
													}),
													this.state.selectedUser
												);
												this.setState({ isAddNewAdminPopoverOpen: false })
											}}
										>
											Дать выбранному сотруднику права модератора
										</Button>
									</div>
								}
							>
								<Button type="primary" disabled={this.state.selectedUser === undefined}>
									Добавить
								</Button>
							</Popover>
						</div>
						<Table
							dataSource={this.state.staff.filter((staff) =>
								this.state.bookingAdminsUserProfiles.find((userProfile) => {
									return userProfile.tsuAccountProfile.accountId === staff.accountId;
								})
							)}
							rowKey={(record) => record.id}
						>
							<Table.Column title="ФИО" dataIndex="name" key="name" />
							<Table.Column
								title="Подразделение"
								dataIndex="department"
								key="department"
								render={(text, record: StaffInfo) => record.department.name}
							/>
							<Table.Column
								title="Роли"
								dataIndex="bookingRoles"
								key="bookingRoles"
								render={(text, record: StaffInfo) => {
									return (
										<>
											<Modal
												title={record.name}
												open={this.state.userId === this.getBookingAdmin(record)?.id!}
												onCancel={() => this.handleCancel()}
												width={700}
												footer={
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Popover
															title="Удалить все роли?"
															placement="right"
															trigger="click"
															open={this.state.isDeleteAllRolesPopoverOpen}
															onOpenChange={(visible) =>
																this.setState({
																	isDeleteAllRolesPopoverOpen: visible,
																})
															}
															content={
																<div className="delete-all-roles-popover-wrapper">
																	<Button
																		type="primary"
																		onClick={() =>
																			this.setState({
																				isDeleteAllRolesPopoverOpen: false,
																			})
																		}
																	>
																		Нет
																	</Button>

																	<Button
																		danger
																		onClick={() => {
																			this.viewModel.removeAllModeratorRoles(this.getBookingAdmin(record)?.id!);
																			this.setState({
																				isDeleteAllRolesPopoverOpen: false,
																			})
																		}
																		}
																	>
																		Да
																	</Button>
																</div>
															}
														>
															<Button
																danger
																onClick={() =>
																	this.setState({
																		isDeleteAllRolesPopoverOpen: true,
																	})
																}
															>
																Удалить все роли
															</Button>
														</Popover>
														<Button onClick={() => this.handleCancel()}>Закрыть окно</Button>
													</div>
												}
											>
												<EditableBookingRolesList
													selectedBookingRoles={this.getBookingAdmin(record)?.bookingRoles!}
													bookingRoles={this.state.bookngRoles}
													ownerId={this.getBookingAdmin(record)?.id!}
													createBookingRole={this.createBookingRole.bind(this)}
													changeRoleHandler={this.changeRoleHandler.bind(this)}
												/>
											</Modal>

											<Button
												type="primary"
												block
												size="small"
												onClick={() => this.showModal(this.getBookingAdmin(record)?.id!)}
											>
												Редактировать роли
											</Button>
											<div className="roles-list-wrapper">
												{this.getBookingAdmin(record)?.bookingRoles.map((role) => {
													return <div className="role-list-item">{role.name}</div>;
												})}
											</div>
										</>
									);
								}}
							/>
							<Table.Column
								dataIndex="actions"
								key="actions"
								render={(text, record: StaffInfo) => (
									<Button
										danger
										icon={<DeleteOutlined />}
										onClick={() => {
											this.viewModel.removeBookingAdmin(record.accountId);
										}}
									/>
								)}
							/>
						</Table>
					</Spin>
				</Card>
			</div >
		);
	}
}