import BookingRoleUseCase from "../../../../domain/booking/interactors/BookingRoleUseCase";
import { BookingRole, BookingRoleBody } from "../../../../domain/booking/models/BookingRole";
import UserProfile from "../../../../domain/common/models/UserProfile";
import UsersUseCase from "../../../../domain/users/interactors/UsersUseCase";
import StaffInfo from "../../../../domain/users/models/StaffInfo";

export default class UsersViewModel {
    staff: StaffInfo[] = [];
    bookingAdminsUserProfiles: UserProfile[] = [];
    bookingRoles: BookingRole[] = [];
    userEmail?: string;

    onDidStartRequest: (() => void) = () => { };
    onDidFinishRequest: (() => void) = () => { };
    onDidUpdate: (() => void) = () => { };
    onDidReceiveError: ((error: Error) => void) = () => { };

    private usersUseCase: UsersUseCase;
    private bookingRoleUseCase: BookingRoleUseCase;

    constructor(usersUseCase: UsersUseCase, bookingRoleUseCase: BookingRoleUseCase) {
        this.usersUseCase = usersUseCase;
        this.bookingRoleUseCase = bookingRoleUseCase;
    }

    loadData() {
        this.onDidStartRequest();
        this.bookingRoleUseCase.getCurrentUser().then(userProfile => {
            this.userEmail = userProfile.email
        });
        Promise.all([
            this.usersUseCase.getStaff(),
            this.bookingRoleUseCase.getBookingRoles(),
            this.usersUseCase.getModerators()
        ])
            .then(response => {
                this.staff = response[0];
                this.bookingRoles = response[1];
                this.bookingAdminsUserProfiles = response[2];
                this.onDidUpdate();
            }).catch(error => {
                this.onDidReceiveError(error);
            }).finally(() => {
                this.onDidFinishRequest();
            });
    }

    addBookingAdmin(bookingRoleIds: string[], accountId?: string) {
        if (accountId) {
            this.onDidStartRequest();

            this.usersUseCase.addBookingAdmin(accountId, bookingRoleIds).then(() => {
                this.getModerators()
                this.onDidUpdate();
            }).catch(error => {
                this.onDidReceiveError(error);
            }).finally(() => {
                this.onDidFinishRequest();
            });
        }
    }

    removeBookingAdmin(accountId?: string) {
        if (accountId) {
            this.onDidStartRequest();
            this.usersUseCase.removeBookingAdmin(accountId).then(() => {
                this.getModerators()
                this.onDidUpdate();
            }).catch(error => {
                this.onDidReceiveError(error);
            }).finally(() => {
                this.onDidFinishRequest();
            });
        }
    }

    getBookingRoles() {
        this.onDidStartRequest();
        this.bookingRoleUseCase.getBookingRoles().then(roles => {
            this.bookingRoles = roles;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    getModerators() {
        this.onDidStartRequest();
        this.usersUseCase.getModerators().then(moderators => {
            this.bookingAdminsUserProfiles = moderators;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    createBookingRole(newBookingRole: BookingRoleBody) {
        this.onDidStartRequest();
        this.bookingRoleUseCase.createBookingRole(newBookingRole).then(() => {
            this.getBookingRoles()
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    addBookingRoleForModerator(moderatorId: string, bookingRoleId: string) {
        this.onDidStartRequest();
        this.usersUseCase.addBookingRoleForModerator(moderatorId, bookingRoleId).then(() => {
            this.getModerators()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    removeBookingRoleForModerator(moderatorId: string, bookingRoleId: string) {
        this.onDidStartRequest();
        this.usersUseCase.removeBookingRoleForModerator(moderatorId, bookingRoleId).then(() => {
            this.getModerators()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    removeAllModeratorRoles(moderatorId: string) {
        this.onDidStartRequest();
        this.usersUseCase.removeAllModeratorRoles(moderatorId).then(() => {
            this.getModerators()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }
}