import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { LogoutOutlined, ScheduleOutlined, TeamOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import UserProfile from "../../../../domain/common/models/UserProfile";
import UserRole from "../../../../domain/common/models/UserRole";

export default function SideMenu() {

    const location = useLocation();
    const [roles, setRoles] = useState<UserRole[]>([]);

    useEffect(() => {
        let data = window.localStorage.getItem("userProfile");
        if (data) {
            let profile = JSON.parse(data) as UserProfile;
            setRoles(profile.roles);
        }
    }, [])
    
    return (
        <Menu
            theme="dark"
            defaultSelectedKeys={['applications']}
            mode="inline"
            selectedKeys={[location.pathname]}
        >
            <Menu.SubMenu
                key={"booking"}
                icon={<ScheduleOutlined />}
                title={<span>Бронирование</span>}>
                <Menu.Item key={"/booking/applications"}>
                    <Link to="/booking/applications">
                        Заявки
                    </Link>
                </Menu.Item>
                <Menu.Item key={"/booking/audienceGroups"} hidden={roles.indexOf(UserRole.admin) === -1}>
                    <Link to="/booking/audienceGroups">
                        Группы аудиторий
                    </Link>

                </Menu.Item>
                <Menu.Item key={"/booking/roles"} hidden={roles.indexOf(UserRole.admin) === -1}>
                    <Link to="/booking/roles">
                        Роли
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={"/users"} hidden={roles.indexOf(UserRole.admin) === -1} icon={<TeamOutlined />}>
                <Link to="/users">
                    Пользователи
                </Link>
            </Menu.Item>
            <Menu.Item key={"/logout"} icon={<LogoutOutlined />}>
                <Link to="/logout">
                    Выход
                </Link>
            </Menu.Item>
        </Menu>
    );
}