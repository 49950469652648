import NetworkService from "../NetworkService";
import HTTPMethod from "../HTTPMethod";
import StaffRepository from "../../../domain/users/repository/StaffRepository";
import StaffInfo from "../../../domain/users/models/StaffInfo";
import EmptyResponse from "../EmptyResponse";
import UserProfile from "../../../domain/common/models/UserProfile";

export default class StaffService extends NetworkService implements StaffRepository {
    getStaff(): Promise<StaffInfo[]> {
        return this.request("/staff", HTTPMethod.get);
    }

    addBookingAdmin(accountId: string, bookingRoleIds: string[]): Promise<EmptyResponse> {
        return this.request(`/staff/${accountId}/booking-admins`, HTTPMethod.post, { bookingRoles: bookingRoleIds });
    }

    removeBookingAdmin(accountId: string): Promise<EmptyResponse> {
        return this.request(`/staff/${accountId}/booking-admins`, HTTPMethod.delete);
    }

    getModerators(): Promise<UserProfile[]> {
        return this.request('/users/moderators', HTTPMethod.get)
    }

    addBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
        return this.request(`/users/moderators/${moderatorId}/booking-roles`, HTTPMethod.post, { bookingRoleId: bookingRoleId })
    }

    removeAllModeratorRoles(moderatorId: string): Promise<EmptyResponse> {
        return this.request(`/users/moderators/${moderatorId}/booking-roles`, HTTPMethod.delete)
    }

    removeBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
        return this.request(`/users/moderators/${moderatorId}/booking-roles/${bookingRoleId}`, HTTPMethod.delete)
    }
}