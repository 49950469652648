import { Button, Col, Row, Table, Spin, Typography, Popover, Form, Input, Empty, FormInstance } from "antd";
import Card from "antd/lib/card/Card";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import React from "react";
import { BookingRole, BookingRoleBody } from "../../../../../domain/booking/models/BookingRole";
import Notifications from "../../../../utils/Notifications";
import RolesViewModel from "../view-models/RolesViewModel";
import "./roles-view.scss"

interface RolesProps {
    viewModel: RolesViewModel
}


interface RolesViewState {
    bookingRoles: BookingRole[],
    filteredBookingRoles: BookingRole[],
    selectedRole?: BookingRole,
    selectedRoleCopy?: BookingRole,
    isLoading: boolean,
    isDeletePopoverVisible: boolean,
    isAddPopoverVisible: boolean,
    userEmail?: string
}

export default class RolesView extends React.Component<RolesProps, RolesViewState>{
    private viewModel: RolesViewModel;
    private newRoleForm = React.createRef<FormInstance<BookingRoleBody>>();

    constructor(props: RolesProps) {
        super(props);
        this.state = {
            bookingRoles: [],
            filteredBookingRoles: [],
            selectedRole: undefined,
            selectedRoleCopy: undefined,
            isLoading: false,
            isDeletePopoverVisible: false,
            isAddPopoverVisible: false,
            userEmail: ''
        };
        this.viewModel = props.viewModel;
    }

    componentDidMount() {
        this.bindToViewModel()
        this.viewModel.getUserEmail();
        this.viewModel.getBookingRoles();
    }

    private bindToViewModel() {
        this.viewModel.onDidStartRequest = () => {
            this.setState({
                isLoading: true
            });
        };
        this.viewModel.onDidFinishRequest = () => {
            this.setState({
                isLoading: false
            });
        };
        this.viewModel.onDidUpdate = () => {
            this.setState({
                bookingRoles: this.viewModel.bookingRoles,
                filteredBookingRoles: this.viewModel.bookingRoles,
                userEmail: this.viewModel.userEmail
            });
        };
        this.viewModel.onDidReceiveError = (error) => {
            Notifications.handle(error);
        };
    }

    hideDeletePopover() {
        this.setState({ isDeletePopoverVisible: false })
    };

    deletePopoverOpenChange(newOpen: boolean) {
        this.setState({ isDeletePopoverVisible: newOpen })
    };

    deleteBookingRole(bookingRoleId: string) {
        this.viewModel.deleteBookingRole(bookingRoleId)
        this.setState({ selectedRoleCopy: undefined })
    }

    updateBookingRole() {
        const newBookingRole: BookingRoleBody = this.state.selectedRoleCopy!;
        this.viewModel.updateBookingRole(this.state.selectedRole!.id, newBookingRole)
        this.setState({ selectedRoleCopy: undefined })
    }

    createBookingRole(value: BookingRoleBody) {
        this.viewModel.createBookingRole(value)
    }

    render() {

        return (
            <div className="audience-groups-page-container">
                <Title>Роли</Title>

                <Card  >
                    {/* @ts-ignore */}
                    <Spin tip="Загрузка..." spinning={this.state.isLoading}>
                        <div className="header-wrapper">
                            <Input.Search
                                className="search-input"
                                placeholder="Название"
                                onSearch={(value: string) => {
                                    this.setState({
                                        filteredBookingRoles: this.state.bookingRoles.filter((record) => {
                                            return record.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                        })
                                    });
                                }}
                                enterButton
                            />

                            <Popover
                                placement="left"
                                trigger="click"
                                visible={this.state.isAddPopoverVisible}
                                onVisibleChange={(val) => this.setState({ isAddPopoverVisible: val })}

                                content={
                                    <Form
                                        ref={this.newRoleForm}
                                        onFinish={(values: BookingRoleBody) => {
                                            this.createBookingRole(values)
                                            this.newRoleForm.current?.resetFields()
                                        }}
                                    >
                                        <Form.Item
                                            name="name"
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Input placeholder="Название роли" />
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true, message: '' }]}
                                        >
                                            <Input placeholder="Эл. почта" />
                                        </Form.Item>

                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                block
                                            >
                                                Добавить роль
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                }
                            >
                                <Button
                                    type="primary"
                                    onClick={() => this.setState({ isAddPopoverVisible: true })}
                                >
                                    Добавить роль
                                </Button>
                            </Popover>
                        </div>

                        <Row gutter={16} className="audience-groups-content-container">
                            <Col span={7}>

                                <Card
                                    bodyStyle={{ padding: 0 }}
                                >
                                    <Table
                                        showHeader={false}
                                        dataSource={this.state.filteredBookingRoles}
                                        rowKey={record => record.id}
                                        pagination={{
                                            size: "small"
                                        }}>
                                        <Table.Column
                                            dataIndex="title"
                                            render={(text, record: BookingRole) => {
                                                return (
                                                    <div className="audience-groups-list-content"
                                                        onClick={() => { this.setState({ selectedRole: record, selectedRoleCopy: record }) }}>
                                                        <Paragraph ellipsis={{
                                                            rows: 1,
                                                        }} className="title">{record.name}</Paragraph>

                                                    </div>
                                                );
                                            }} />
                                    </Table>
                                </Card>
                            </Col>
                            <Col span={17}>
                                <Card>
                                    {this.state.selectedRoleCopy ?

                                        <>
                                            <div className="buttons-wrapper">


                                                <Popover
                                                    placement="left"
                                                    trigger="click"
                                                    title="Удалить роль?"
                                                    visible={this.state.isDeletePopoverVisible}
                                                    onVisibleChange={(val) => this.deletePopoverOpenChange(val)}
                                                    content={
                                                        <div style={{ display: 'flex', gap: '16px' }}>
                                                            <Button
                                                                danger
                                                                onClick={() => { this.deleteBookingRole(this.state.selectedRole!.id); this.hideDeletePopover() }}
                                                            >
                                                                Да
                                                            </Button>

                                                            <Button
                                                                type="primary"
                                                                onClick={() => this.hideDeletePopover()}
                                                            >
                                                                Нет
                                                            </Button>
                                                        </div>
                                                    }
                                                >

                                                    <Button danger>
                                                        Удалить роль
                                                    </Button>
                                                </Popover>
                                                <Form.Item>
                                                    <div>
                                                        <Button
                                                            type="primary"
                                                            htmlType="submit"
                                                            disabled={
                                                                JSON.stringify(this.state.selectedRole) === JSON.stringify(this.state.selectedRoleCopy) ||
                                                                this.state.selectedRoleCopy.name === "" ||
                                                                this.state.selectedRoleCopy.email === ""
                                                            }
                                                            onClick={() => this.updateBookingRole()}
                                                        >
                                                            Применить изменения
                                                        </Button>
                                                    </div>
                                                </Form.Item>
                                            </div>

                                            <Form.Item
                                                validateStatus={this.state.selectedRoleCopy.name === "" ? 'error' : 'validating'}
                                                help={this.state.selectedRoleCopy.name === "" ? 'Введите название роли' : ''}
                                            >
                                                <Typography.Title
                                                    style={{ margin: 0, marginRight: '8px' }}
                                                    editable={{
                                                        onChange: (value) => this.setState({ selectedRoleCopy: { ...this.state.selectedRoleCopy!, name: value } })
                                                    }}
                                                >
                                                    {this.state.selectedRoleCopy.name}
                                                </Typography.Title>
                                            </Form.Item>

                                            <div className="email-wrapper">
                                                <Typography.Title level={5} style={{ marginRight: '8px' }}>Почта: </Typography.Title>

                                                <Form.Item
                                                    validateStatus={this.state.selectedRoleCopy.email === "" ? 'error' : 'validating'}
                                                    help={this.state.selectedRoleCopy.email === "" ? 'Введите почту' : ''}
                                                >
                                                    <Typography.Title
                                                        level={5}

                                                        editable={{
                                                            onChange: (value) => { this.setState({ selectedRoleCopy: { ...this.state.selectedRoleCopy!, email: value } }) }
                                                        }}
                                                    >
                                                        {this.state.selectedRoleCopy.email}
                                                    </Typography.Title>
                                                </Form.Item>
                                            </div>

                                        </>
                                        :
                                        <Empty />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Spin>
                </Card >
            </div >
        )
    }
}