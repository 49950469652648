import BookingRepository from "../repository/BookingRepository";
import BookingApplication from "../models/BookingApplication";
import EmptyResponse from "../../../data/network-service/EmptyResponse";
import UserProfile from "../../common/models/UserProfile";

export default class BookingUseCase {
    private bookingRepository: BookingRepository

    constructor(bookingRepository: BookingRepository) {
        this.bookingRepository = bookingRepository
    }

    getBookingApplications(): Promise<BookingApplication[]> {
        return this.bookingRepository.getBookingApplications();
    }

    approveBooking(bookingID: string): Promise<EmptyResponse> {
        return this.bookingRepository.approveBooking(bookingID);
    }

    rejectBooking(bookingID: string, reason: string): Promise<EmptyResponse> {
        return this.bookingRepository.rejectBooking(bookingID, reason);
    }

    getCurrentUser(): Promise<UserProfile> {
        return this.bookingRepository.getCurrentUser();
    }
}