import { Button, Col, Form, Input, Row, Select, Typography, Checkbox, Switch, List, Divider, FormInstance, Popover } from "antd";
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { DefaultOptionType } from "antd/lib/select";
import React from "react";
import Audience from "../../../../../../domain/buildings/models/Audience";
import Building from "../../../../../../domain/buildings/models/Building";
import MDEditor from '@uiw/react-md-editor';
import { BookingRole } from "../../../../../../domain/booking/models/BookingRole";
import {
    BookingAudienceGroup,
    BookingAudienceGroupBody,
    BookingFormField,
    BookingFormFieldBody
} from "../../../../../../domain/booking/models/BookingAudienceGroup";
import EditableBookingRolesList from "../../../../../components/EditableBookingRolesList";

const { Option } = Select;

export interface SelectedGroupProps {

    selectedGroup?: BookingAudienceGroup;
    buildings: Building[];
    audiences: Audience[];
    audienceFilter: string;
    bookingRoles: BookingRole[];
    bookingRolesFilter: string;
    isNotifyAboutNewBookingsRequested: boolean,
    isDeletePopoverVisible: boolean;
    formFieldTypes: string[];
    bookingAudienceGroups: BookingAudienceGroup[];

    deleteBookingAudienceGroup(audienceGroupId: string): void;
    createBookingRole(value: string, e: any): void;
    bookingRolesFilterChanged(changeEvent: React.ChangeEvent<HTMLInputElement>): void;
    getFilteredArray(array: Audience[] | BookingRole[], input: string): Audience[] | BookingRole[];
    buildingChangedHandler(selectedBuilding: string): void;
    filterOptionForSelector(input: string, option: DefaultOptionType | undefined): boolean;
    audienceFilterChanged(changeEvent: React.ChangeEvent<HTMLInputElement>): void;
    deletePopoverOpenChange(newOpen: boolean): void;
    hideDeletePopover(): void;
    updateBookingAudienceGroup(audienceGroupCopy: BookingAudienceGroupBody): void;
    deleteRoleFromGroup(ownerId: string, roleId: string): void;
    addRoleInGroup(ownerId: string, roleId: string): void;
    addAudienceInGroup(audienceId: string): void;
    deleteAudienceFromGroup(audienceId: string): void;
    updateFormFieldInGroup(formField: BookingFormField): void;
    deleteFormFieldFromGroup(formField: BookingFormField): void;
    addFormFieldInGroup(formField: BookingFormFieldBody): void;
}

interface SelectedGroupFormState {
    selectedGroupCopy: BookingAudienceGroup,
    visibleFormPopoverIndex: number | null
}

export default class SelectedGroupForm extends React.Component<SelectedGroupProps, SelectedGroupFormState>{

    additionalFieldsFormWrapper = React.createRef<FormInstance>();
    constructor(props: SelectedGroupProps) {
        super(props);

        this.state = {
            selectedGroupCopy: this.props.selectedGroup!,
            visibleFormPopoverIndex: null
        };
    }

    changeName(value: string) {
        this.setState({
            selectedGroupCopy: {
                ...this.state.selectedGroupCopy!,
                name: value
            }
        })
    }

    changeRoleHandler(e: any, ownerId: string, role: BookingRole) {
        if (e.target.checked) {
            this.props.addRoleInGroup(ownerId, role.id);
        }
        else {
            this.props.deleteRoleFromGroup(ownerId, role.id);
        }
    }

    changeAudienceHandler(e: any, audience: Audience) {
        if (e.target.checked) {
            this.props.addAudienceInGroup(audience.id);
        }
        else {
            this.props.deleteAudienceFromGroup(audience.id);
        }
    }

    isNotifyAboutNewBookingsRequested() {
        this.setState({
            selectedGroupCopy: {
                ...this.state.selectedGroupCopy!,
                isNotifyAboutNewBookingsRequested: !this.state.selectedGroupCopy?.isNotifyAboutNewBookingsRequested
            }
        })
    }

    changeEmailTemplate(value: string) {
        if (value) {
            this.setState({
                selectedGroupCopy: {
                    ...this.state.selectedGroupCopy!,
                    emailTemplate: value
                }
            })
        }
        else {
            this.setState({
                selectedGroupCopy: {
                    ...this.state.selectedGroupCopy!,
                    emailTemplate: null
                }
            })
        }
    }

    isUpdateButtonDisabled() {
        return (this.state.selectedGroupCopy.name === this.props.selectedGroup?.name &&
            this.state.selectedGroupCopy.emailTemplate === this.props.selectedGroup?.emailTemplate &&
            this.state.selectedGroupCopy.isNotifyAboutNewBookingsRequested === this.props.selectedGroup?.isNotifyAboutNewBookingsRequested) ||
            this.state.selectedGroupCopy.name === ""
    }

    updateGroup() {
        const newGroup: BookingAudienceGroupBody = {
            name: this.state.selectedGroupCopy.name,
            isNotifyAboutNewBookingsRequested: this.state.selectedGroupCopy.isNotifyAboutNewBookingsRequested,
            emailTemplate: this.state.selectedGroupCopy.emailTemplate
        }

        this.props.updateBookingAudienceGroup(newGroup)
    }

    putFormFieldToGroup(formField: BookingFormField) {
        this.props.updateFormFieldInGroup(formField)
    }

    postFormFieldToGroup(formField: BookingFormField) {
        this.props.addFormFieldInGroup(formField)
    }

    deleteFormFieldToGroup(formField: BookingFormField) {
        this.props.deleteFormFieldFromGroup(formField)
    }

    getFieldValuesOfAdditionalField(name: number) {
        return this.additionalFieldsFormWrapper.current?.getFieldValue(['AdditionalFieldsOfSelectedGroup', name])
    }


    hideDeleteFormPopover() {
        this.setState({ visibleFormPopoverIndex: null })
    }

    deleteFormPopoverOpenChange(isOpen: boolean) {
        if (!isOpen) {
            this.setState({ visibleFormPopoverIndex: null })
        }
    }

    headerButtonslayout() {
        return (
            <div className="buttons-wrapper">

                <Popover
                    placement="left"
                    trigger="click"
                    title="Удалить группу?"
                    visible={this.props.isDeletePopoverVisible}
                    onVisibleChange={(val) => this.props.deletePopoverOpenChange(val)}
                    content={
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <Button
                                danger
                                onClick={() => {
                                    this.props.deleteBookingAudienceGroup(this.props.selectedGroup!.id);
                                    this.props.hideDeletePopover()
                                }}
                            >
                                Да
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => this.props.hideDeletePopover()}
                            >
                                Нет
                            </Button>
                        </div>
                    }
                >

                    <Button danger>
                        Удалить группу
                    </Button>
                </Popover>
                <Form.Item>
                    <div className="buttons-wrapper">
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={this.isUpdateButtonDisabled()}
                            onClick={() => this.updateGroup()}
                        >
                            Применить изменения
                        </Button>
                    </div>
                </Form.Item>
            </div>
        )
    }

    nameAndEmailTemplateLayout() {
        return (
            <>
                <Form.Item
                    validateStatus={this.state.selectedGroupCopy.name === "" ? 'error' : 'validating'}
                    help={this.state.selectedGroupCopy.name === "" ? 'Введите название группы' : ''}
                >
                    <Typography.Title
                        editable={{
                            onChange: (value) => { this.changeName(value) }
                        }}
                    >
                        {this.state.selectedGroupCopy.name}
                    </Typography.Title>
                </Form.Item>


                <Form.Item className="message-form">
                    <div className="message-form-switch-wrapper">
                        <Switch
                            checked={this.state.selectedGroupCopy.isNotifyAboutNewBookingsRequested}
                            onChange={() => this.isNotifyAboutNewBookingsRequested()}
                        />
                        <span>
                            Отправлять письмо при создании заявки
                        </span>
                    </div>


                    <div
                        className="message-form-redactor"
                        hidden={!this.state.selectedGroupCopy.isNotifyAboutNewBookingsRequested}
                    >
                        <MDEditor
                            data-color-mode="light"
                            value={this.state.selectedGroupCopy?.emailTemplate as string}
                            onChange={(value) => this.changeEmailTemplate(value!)}
                        />
                    </div>

                </Form.Item>
            </>
        )
    }

    audiencesListLayout() {

        let otherGroups = this.props.bookingAudienceGroups.filter(
            group => group.id !== '00000000-0000-0000-0000-000000000000' &&
                group.id !== this.state.selectedGroupCopy.id
        )
        let audsOfOtherGroups: Audience[] = []
        otherGroups.map(group => audsOfOtherGroups.push(...group.audiences))
        const audiencesWithoutGroup = this.props.audiences.filter(aud => !audsOfOtherGroups.some(x => x.id === aud.id))

        return (
            <Row gutter={16} className="row-wrapper">
                <Col span={10} className="list-wrapper">
                    <List
                        size="small"
                        header={
                            <div className="list-header">
                                <Typography.Paragraph>Список аудиторий: </Typography.Paragraph>
                            </div>
                        }
                        bordered
                        dataSource={this.props.selectedGroup?.audiences}
                        renderItem={
                            item =>
                                <List.Item
                                    extra={
                                        <div className="delete-field-button-wrapper">
                                            <CloseCircleOutlined checked={false} onClick={(e) => { this.changeAudienceHandler(e, item) }} />
                                        </div>
                                    }
                                >
                                    <div className="content-wrapper">{item.name}</div>
                                </List.Item>
                        }
                    />
                </Col>

                <Col span={14}>

                    <Form.Item>
                        <Select
                            showSearch
                            className="defaultSelector"
                            placeholder="Номер корпуса"
                            optionFilterProp="children"
                            onChange={(value) => this.props.buildingChangedHandler(value)}
                            filterOption={(input, option) => this.props.filterOptionForSelector(input, option)}
                        >
                            {
                                this.props.buildings?.map(building => {
                                    return <Option key={building.id} value={building.id}>{building.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Input
                            placeholder='Поиск'
                            prefix={<SearchOutlined />}
                            onChange={(changeEvent) => this.props.audienceFilterChanged(changeEvent)}
                        />
                    </Form.Item>

                    <div className="list-wrapper">
                        <List
                            size="small"
                            bordered
                            dataSource={this.props.getFilteredArray(audiencesWithoutGroup, this.props.audienceFilter)}
                            renderItem={
                                item =>
                                    <List.Item
                                    >
                                        <Checkbox
                                            checked={this.props.selectedGroup?.audiences.some(audience => audience.id === item.id)}
                                            onChange={(e) => { this.changeAudienceHandler(e, item) }}
                                        > {
                                                item.name}
                                        </Checkbox>
                                    </List.Item>
                            }
                        />
                    </div>
                </Col>
            </Row>
        )
    }

    additionalListLayout() {
        return (
            <div className="additional-fields">
                <Form
                    name="additionalFieldsWrapper"
                    ref={this.additionalFieldsFormWrapper}
                    initialValues={{ AdditionalFieldsOfSelectedGroup: this.props.selectedGroup?.bookingFormAdditionalFields }}
                >
                    <Form.List name='AdditionalFieldsOfSelectedGroup' >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (

                                    <div className="additional-filed-input-wrapper">
                                        <div className="additional-filed-input-wrapper-formItems">

                                            <div className="form-items-row">

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'title']}
                                                    rules={[{ required: true, message: '' }]}
                                                >
                                                    <Input placeholder="название поля" />
                                                </Form.Item>


                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'templateId']}
                                                    rules={[{ required: true, message: '' }]}
                                                >
                                                    <Input placeholder="название переменной" />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'type']}
                                                    rules={[{ required: true, message: '' }]}
                                                >
                                                    <Select
                                                        className="form-items-row-type-selector"
                                                        defaultValue={"тип данных"}
                                                        options={this.props.formFieldTypes.map(type => {
                                                            return { value: type, label: type }
                                                        })}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <Form.Item
                                                {...restField}
                                                className="form-items-checkbox-wrapper"
                                                name={[name, 'isOptional']}
                                                initialValue={false}
                                                valuePropName="checked"
                                            >
                                                <Checkbox value={false}>Поле опционально для заполнения?</Checkbox>
                                            </Form.Item>

                                            <Button
                                                onClick={() => {
                                                    this.props.selectedGroup?.bookingFormAdditionalFields.length! > name ?
                                                        this.putFormFieldToGroup(this.getFieldValuesOfAdditionalField(name)) :
                                                        this.postFormFieldToGroup(this.getFieldValuesOfAdditionalField(name))
                                                }}
                                            >
                                                {this.props.selectedGroup?.bookingFormAdditionalFields.length! > name ? 'Обновить' : 'Добавить'}
                                            </Button>
                                        </div>

                                        <div className="delete-field-button-wrapper">
                                            <Popover
                                                title="Удалить поле?"
                                                placement="left"
                                                trigger="click"
                                                visible={this.state.visibleFormPopoverIndex === name}
                                                onOpenChange={(isOpen) => this.deleteFormPopoverOpenChange(isOpen)}
                                                content={
                                                    <div style={{ display: 'flex', gap: '16px' }}>
                                                        <Button
                                                            danger
                                                            onClick={() => {
                                                                this.deleteFormFieldToGroup(this.getFieldValuesOfAdditionalField(name));
                                                                this.hideDeleteFormPopover()
                                                            }}
                                                        >
                                                            Да
                                                        </Button>

                                                        <Button
                                                            type="primary"
                                                            onClick={() => this.hideDeleteFormPopover()}
                                                        >
                                                            Нет
                                                        </Button>
                                                    </div>
                                                }
                                            >
                                                <CloseCircleOutlined
                                                    onClick={() =>
                                                        this.props.selectedGroup?.bookingFormAdditionalFields.length! > name ?
                                                            this.setState({ visibleFormPopoverIndex: name }) :
                                                            remove(name)
                                                    }
                                                />
                                            </Popover>

                                        </div>
                                    </div>
                                ))}
                                <Form.Item>
                                    <div className="add-field-button-wrapper">
                                        <Button
                                            title="Добавьте или удалите созданное поле"
                                            type="dashed"
                                            disabled={fields.length === this.props.selectedGroup?.bookingFormAdditionalFields.length! + 1}
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}>
                                            Добавить поле
                                        </Button>
                                    </div>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </div>
        )
    }

    render() {
        if (this.state.selectedGroupCopy?.id !== this.props.selectedGroup?.id) {
            this.setState({ selectedGroupCopy: this.props.selectedGroup! })
        }

        this.additionalFieldsFormWrapper.current?.setFieldsValue({
            'AdditionalFieldsOfSelectedGroup': this.props.selectedGroup?.bookingFormAdditionalFields
        })

        return (
            <>
                {this.headerButtonslayout()}
                {this.nameAndEmailTemplateLayout()}
                <Divider />
                <EditableBookingRolesList
                    selectedBookingRoles={this.props.selectedGroup!.requiredRoles}
                    bookingRoles={this.props.bookingRoles}
                    ownerId={this.props.selectedGroup!.id}
                    createBookingRole={this.props.createBookingRole.bind(this)}
                    changeRoleHandler={this.changeRoleHandler.bind(this)}
                />
                <Divider />
                {this.audiencesListLayout()}
                <Divider />
                {this.additionalListLayout()}
            </>
        )
    }
}