import BookingRepository from "../repository/BookingRepository";
import { BookingRole, BookingRoleBody } from "../models/BookingRole";
import EmptyResponse from "../../../data/network-service/EmptyResponse";
import UserProfile from "../../common/models/UserProfile";

export default class BookingRoleUseCase {
    private bookingRepository: BookingRepository

    constructor(bookingRepository: BookingRepository) {
        this.bookingRepository = bookingRepository
    }

    getBookingRoles(): Promise<BookingRole[]> {
        return this.bookingRepository.getBookingRoles();
    }

    createBookingRole(newBookingRole: BookingRoleBody): Promise<EmptyResponse> {
        return this.bookingRepository.createBookingRole(newBookingRole);
    }

    updateBookingRole(roleId: string, newBookingRole: BookingRoleBody): Promise<EmptyResponse> {
        return this.bookingRepository.updateBookingRole(roleId, newBookingRole);
    }

    deleteBookingRole(bookingRoleId: string): Promise<EmptyResponse> {
        return this.bookingRepository.deleteBookingRole(bookingRoleId);
    }

    getCurrentUser(): Promise<UserProfile> {
        return this.bookingRepository.getCurrentUser();
    }
}