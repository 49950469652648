import BookingUseCase from "../../../../../domain/booking/interactors/BookingUseCase";
import BookingApplication from "../../../../../domain/booking/models/BookingApplication";
import UserProfile from "../../../../../domain/common/models/UserProfile";

export default class BookingApplicationsViewModel {
    applications: BookingApplication[] = [];
    currentUser?: UserProfile;

    onDidStartRequest: (() => void) = () => { };
    onDidFinishRequest: (() => void) = () => { };
    onDidUpdate: (() => void) = () => { };
    onDidReceiveError: ((error: Error) => void) = () => { };

    private bookingUseCase: BookingUseCase;

    constructor(bookingUseCase: BookingUseCase) {
        this.bookingUseCase = bookingUseCase;
    }

    getBookingApplications() {
        this.onDidStartRequest();
        this.bookingUseCase.getBookingApplications().then(applications => {
            this.applications = applications;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    async loadData() {
        this.onDidStartRequest();
        try {
            this.currentUser = await this.bookingUseCase.getCurrentUser()
            this.applications = await this.bookingUseCase.getBookingApplications()
            this.onDidUpdate();
        }
        catch (error: any) {
            this.onDidReceiveError(error);
        }
        finally {
            this.onDidFinishRequest();
        }

    }

    approveBooking(bookingID: string) {
        this.onDidStartRequest();
        this.bookingUseCase.approveBooking(bookingID).then(() => {
            this.getBookingApplications()
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    rejectBooking(bookingID: string, reason: string) {
        this.onDidStartRequest();
        this.bookingUseCase.rejectBooking(bookingID, reason).then(() => {
            this.getBookingApplications()
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    isApplicationApprovedByRoleOfUser(application: BookingApplication): 'INVALID_REQUIRED_ROLES' | 'APPROVED' | 'NOT_APPROVED' {

        const requiredRolesOfUser = this.currentUser?.bookingRoles?.filter(role =>
            application.requiredBookingRoles.some(applicationRole =>
                applicationRole.id === role.id))

        if (requiredRolesOfUser !== undefined && requiredRolesOfUser.length > 0) {

            const isApplicationApprovedByRoleOfUser = application.approvedByRoles.some(
                approvedRole => requiredRolesOfUser.some(requiredRole => requiredRole.id === approvedRole.id))

            return isApplicationApprovedByRoleOfUser ? 'APPROVED' : 'NOT_APPROVED'
        }
        else {
            return 'INVALID_REQUIRED_ROLES'
        }
    }
}