import AuthRepository from "../repository/AuthRepository";
import AuthResponse from "../models/AuthResponse";

export default class LoginUseCase {
    private authRepository: AuthRepository;

    constructor(authRepository: AuthRepository) {
        this.authRepository = authRepository;
    }

    loginWithEmail(email: string, password: string): Promise<AuthResponse> {
        return this.authRepository.loginWithEmail(email, password);
    }

    loginWithTsuAccount(token: string): Promise<AuthResponse> {
        return this.authRepository.loginWithTsuAccount(token);
    }
}