import LogoutUseCase from "../../../../../domain/auth/interactors/LogoutUseCase";

export interface LogoutViewModelDelegate {
    loginFinishViewModelHasLogout(): void
}

export default class LogoutViewModel {
    delegate?: LogoutViewModelDelegate

    private logoutUseCase: LogoutUseCase;

    constructor(logoutUseCase: LogoutUseCase) {
        this.logoutUseCase = logoutUseCase
    }

    logout() {
        this.logoutUseCase.logout();
        this.delegate?.loginFinishViewModelHasLogout();
    }
}