
import BookingAudienceGroupUseCase from "../../../../../domain/booking/interactors/BookingAudienceGroupUseCase";
import BookingRoleUseCase from "../../../../../domain/booking/interactors/BookingRoleUseCase";
import { BookingAudienceGroup, BookingAudienceGroupBody, BookingFormFieldBody } from "../../../../../domain/booking/models/BookingAudienceGroup";
import { BookingRole, BookingRoleBody } from "../../../../../domain/booking/models/BookingRole";
import Audience from "../../../../../domain/buildings/models/Audience";
import Building from "../../../../../domain/buildings/models/Building";

export default class AudienceGroupsViewModel {

    bookingAudienceGroups: BookingAudienceGroup[] = [];
    newCreatedAudienceGroupId?: string = undefined;
    buildings: Building[] = [];
    audiences: Audience[] = [];
    bookingFormFieldsTypes: string[] = [];
    bookingRoles: BookingRole[] = [];
    userEmail?: string;

    onDidStartRequest: (() => void) = () => { };
    onDidFinishRequest: (() => void) = () => { };
    onDidUpdate: (() => void) = () => { };
    onDidReceiveError: ((error: Error) => void) = () => { };

    private audienceGroupsUseCase: BookingAudienceGroupUseCase;
    private bookingRoleUseCase: BookingRoleUseCase;

    constructor(audienceGroupsUseCase: BookingAudienceGroupUseCase, bookingRoleUseCase: BookingRoleUseCase) {
        this.audienceGroupsUseCase = audienceGroupsUseCase;
        this.bookingRoleUseCase = bookingRoleUseCase
    }

    initViewModel() {
        this.onDidStartRequest();
        Promise.all(
            [
                this.audienceGroupsUseCase.getBuildings(),
                this.bookingRoleUseCase.getBookingRoles(),
                this.audienceGroupsUseCase.getBookingFormFieldsTypes(),
                this.audienceGroupsUseCase.getBookingGroups(),
                this.bookingRoleUseCase.getCurrentUser()
            ]).then((response) => {
                this.buildings = response[0];
                this.bookingRoles = response[1];
                this.bookingFormFieldsTypes = response[2];
                this.bookingAudienceGroups = response[3];
                this.userEmail = response[4].email
                this.onDidUpdate();
            }).catch(error => {
                this.onDidReceiveError(error);
            }).finally(() => {
                this.onDidFinishRequest();
            });
    }

    getBookingGroups() {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.getBookingGroups().then(bookingAudienceGroups => {
            this.bookingAudienceGroups = bookingAudienceGroups;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    getBookingRoles() {
        this.onDidStartRequest();
        this.bookingRoleUseCase.getBookingRoles().then(roles => {
            this.bookingRoles = roles;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    createBookingRole(newBookingRole: BookingRoleBody) {
        this.onDidStartRequest();
        this.bookingRoleUseCase.createBookingRole(newBookingRole).then(() => {
            this.getBookingRoles()
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    getAudiences(buildingId: string) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.getAudiences(buildingId).then(audiences => {
            this.audiences = audiences;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    async createBookingAudienceGroup(newBookingAudienceGroup: BookingAudienceGroupBody, audienceIds: string[], roleIds: string[], fields: BookingFormFieldBody[]) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.createBookingAudienceGroup(newBookingAudienceGroup).then((newGroup: BookingAudienceGroup) => {

            const newGroupId = newGroup.id

            Promise.allSettled(
                [
                    ...audienceIds.map(id => { return this.audienceGroupsUseCase.addAudienceInGroup(newGroupId, id) }),
                    ...roleIds.map(id => { return this.audienceGroupsUseCase.addRoleInGroup(newGroupId, id) }),
                    ...fields.map(field => { return this.audienceGroupsUseCase.addFormFieldInGroup(newGroupId, field) })
                ]).then(() => {
                    this.newCreatedAudienceGroupId = newGroupId;
                    this.getBookingGroups()
                })

        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    updateBookingAudienceGroup(groupId: string, newBookingAudienceGroup: BookingAudienceGroupBody) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.updateBookingAudienceGroup(groupId, newBookingAudienceGroup).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    deleteBookingAudienceGroup(audienceGroupId: string) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.deleteBookingAudienceGroup(audienceGroupId).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    getBookingFormFieldsTypes() {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.getBookingFormFieldsTypes().then(bookingFormFieldsTypes => {
            this.bookingFormFieldsTypes = bookingFormFieldsTypes
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    deleteRoleFromGroup(groupId: string, roleId: string) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.deleteRoleFromGroup(groupId, roleId).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    addRoleInGroup(groupId: string, roleId: string) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.addRoleInGroup(groupId, roleId).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    deleteAudienceFromGroup(groupId: string, audienceId: string) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.deleteAudienceFromGroup(groupId, audienceId).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    addAudienceInGroup(groupId: string, audienceId: string) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.addAudienceInGroup(groupId, audienceId).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    updateFormFieldInGroup(groupId: string, formFieldId: string, newFormField: BookingFormFieldBody) {
        this.onDidStartRequest();
        this.audienceGroupsUseCase.updateFormFieldInGroup(groupId, formFieldId, newFormField).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    deleteFormFieldFromGroup(groupId: string, formFieldId: string) {
        this.onDidStartRequest();
        return this.audienceGroupsUseCase.deleteFormFieldFromGroup(groupId, formFieldId).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }

    addFormFieldInGroup(groupId: string, formField: BookingFormFieldBody) {
        this.onDidStartRequest();
        return this.audienceGroupsUseCase.addFormFieldInGroup(groupId, formField).then(() => {
            this.getBookingGroups()
        }).catch(error => {
            this.onDidReceiveError(error);
        })
    }
}