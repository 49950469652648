import LoginUseCase from "../../../../../domain/auth/interactors/LoginUseCase";

enum Constants {
    tokenQueryParameter = "token"
}

export interface LoginFinishViewModelDelegate {
    loginFinishViewModelHasAuthorized(): void
}

export default class LoginFinishViewModel {
    delegate?: LoginFinishViewModelDelegate

    onDidReceiveError: ((error: Error) => void) = () => {};

    private loginUseCase: LoginUseCase

    constructor(loginUseCase: LoginUseCase) {
        this.loginUseCase = loginUseCase
    }

    finishAuthorization() {
        let query = new URLSearchParams(window.location.search);
        let token = query.get(Constants.tokenQueryParameter);

        if (token) {
            this.loginUseCase.loginWithTsuAccount(token).then(response => {
                this.delegate?.loginFinishViewModelHasAuthorized();
            }).catch(error => {
                this.onDidReceiveError(error);
            })
        }
    }
}