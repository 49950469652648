import UserProfile from "../../common/models/UserProfile";
import AudienceWithBuilding from "../../common/models/AudienceWithBuilding";
import { BookingRole } from "./BookingRole";
import { BookingFormField } from "./BookingAudienceGroup";

export enum BookingApplicationStatus {
    new = "NEW",
    approved = "APPROVED",
    rejected = "REJECTED",
    canceled = "CANCELED",
    requireApprove = "REQUIRE_APPROVE"
}

interface BookingLesson {
    id: string;
    lessonNumber: number;
    start: number;
    end: number;
}

export default interface BookingApplication {
    id: string;
    title: string;
    participantCount: string;
    date: string;
    status: BookingApplicationStatus;
    user: UserProfile;
    audience: AudienceWithBuilding;
    lessons: BookingLesson[];
    requiredBookingRoles: BookingRole[];
    approvedByRoles: BookingRole[];
    additionalFields: {
        id: string;
        field: BookingFormField;
        value: string;
    }[];
}