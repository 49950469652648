import { Col, Row, Typography, Form, Input, List, Checkbox, Button } from "antd";
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import React from "react";
import { BookingRole } from "../../domain/booking/models/BookingRole";

interface EditableBookingRolesListProps {
    selectedBookingRoles: BookingRole[];
    bookingRoles: BookingRole[];
    ownerId: string;

    createBookingRole(value: string, e: any): void;
    changeRoleHandler(e: any, ownerId: string, role: BookingRole): void;
}

interface EditableBookingRolesListState {
    bookingRolesFilter: string;
    newBookingRoleName: string;
}

export default class EditableBookingRolesList extends React.Component<EditableBookingRolesListProps, EditableBookingRolesListState> {

    constructor(props: EditableBookingRolesListProps) {
        super(props);
        this.state = {
            bookingRolesFilter: '',
            newBookingRoleName: ''
        };
    }

    bookingRolesFilterChanged(changeEvent: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ bookingRolesFilter: changeEvent.currentTarget.value })
    }

    getFilteredArray(array: BookingRole[], input: string): BookingRole[] {
        return array?.filter(element => element.name.toLowerCase().includes(input.trim().toLowerCase()) === true)
    }

    private isCheckboxDisabled(item: BookingRole) {
        return this.props.selectedBookingRoles.length === 1 &&
            this.props.selectedBookingRoles.some(role => role.id === item.id)
    }

    render() {

        return (
            <Row gutter={16} className="row-wrapper">
                <Col span={10} className="list-wrapper roles-wrapper">

                    <List
                        size="small"
                        header={
                            <div className="list-header">
                                <Typography.Paragraph>Список ролей: </Typography.Paragraph>

                            </div>
                        }
                        footer={
                            <div>
                                <Input.Search
                                    className="newRoleInputSearch"
                                    size="small"
                                    onSearch={(val, e) => this.props.createBookingRole(val, e)}
                                    onChange={e => this.setState({ newBookingRoleName: e.currentTarget.value.trim() })}
                                    placeholder="Название новой роли"
                                    enterButton={
                                        <Button
                                            type='primary'
                                            disabled={this.state.newBookingRoleName === ''}
                                        >
                                            Добавить роль
                                        </Button>
                                    }
                                />
                            </div>
                        }

                        bordered
                        dataSource={this.props.selectedBookingRoles}
                        renderItem={
                            item =>
                                <List.Item
                                    extra={

                                        this.isCheckboxDisabled(item) ?
                                            <div className={`delete-field-button-wrapper disabled`}>
                                                <CloseCircleOutlined
                                                    checked={false}
                                                    title={'Группа аудиторий должна содержать как минимум одну роль'}
                                                />
                                            </div> :
                                            <div className={`delete-field-button-wrapper`}>
                                                <CloseCircleOutlined
                                                    checked={false}
                                                    onClick={(e) => {
                                                        this.props.changeRoleHandler(e, this.props.ownerId, item)
                                                    }}
                                                />
                                            </div>

                                    }
                                >
                                    <div className="content-wrapper">{item.name}</div>
                                </List.Item>
                        }
                    />
                </Col>

                <Col span={14}>
                    <Form.Item name="roleFilter">
                        <Input
                            placeholder='Поиск'
                            prefix={<SearchOutlined />}
                            onChange={(changeEvent) => this.bookingRolesFilterChanged(changeEvent)}
                        />
                    </Form.Item>

                    <div className="list-wrapper">
                        <List
                            size="small"
                            bordered
                            dataSource={this.getFilteredArray(this.props.bookingRoles, this.state.bookingRolesFilter) as BookingRole[]}
                            renderItem={
                                item =>
                                    <List.Item
                                        title={
                                            this.isCheckboxDisabled(item) ?
                                                'Группа аудиторий должна содержать как минимум одну роль' :
                                                undefined
                                        }
                                    >
                                        <Checkbox
                                            checked={this.props.selectedBookingRoles.some(role => role.id === item.id)}
                                            onChange={(e) => { this.props.changeRoleHandler(e, this.props.ownerId, item) }}
                                            disabled={this.isCheckboxDisabled(item)}
                                        >
                                            {item.name}
                                        </Checkbox>
                                    </List.Item>
                            }
                        />
                    </div>


                </Col>
            </Row>
        )
    }
}