import { Col, Row, Typography, Form, Input, List, Checkbox, Button } from "antd";
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import React from "react";
import { BookingRole } from "../../domain/booking/models/BookingRole";

interface BookingRolesListProps {
    selectedBookingRoles: BookingRole[];
    bookingRoles: BookingRole[];

    createBookingRole(value: string, e: any): void;
    bookingRolesCheckboxChanged(e: any, role: BookingRole): void;
}

interface BookingRolesListState {
    bookingRolesFilter: string;
    newBookingRoleName: string;
}

export default class BookingRolesList extends React.Component<BookingRolesListProps, BookingRolesListState> {

    constructor(props: BookingRolesListProps) {
        super(props);
        this.state = {
            bookingRolesFilter: '',
            newBookingRoleName: ''
        };
    }

    bookingRolesFilterChanged(changeEvent: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ bookingRolesFilter: changeEvent.currentTarget.value })
    }

    getFilteredArray(array: BookingRole[], input: string): BookingRole[] {
        return array?.filter(element => element.name.toLowerCase().includes(input.trim().toLowerCase()) === true)
    }

    render() {
        return (
            <Row gutter={16} className="row-wrapper">
                <Col span={10}>
                    <div className="list-wrapper">
                        <List
                            size="small"
                            header={
                                <div className="list-header">
                                    <Typography.Paragraph>Список ролей: </Typography.Paragraph>

                                </div>
                            }
                            footer={
                                <div>
                                    <Input.Search
                                        className="newRoleInputSearch"
                                        size="small"
                                        onSearch={(val, e) => this.props.createBookingRole(val, e)}
                                        onChange={e => this.setState({ newBookingRoleName: e.currentTarget.value.trim() })}
                                        placeholder="Название новой роли"
                                        enterButton={
                                            <Button
                                                type='primary'
                                                disabled={this.state.newBookingRoleName === ''}
                                            >
                                                Добавить роль
                                            </Button>
                                        }
                                    />
                                </div>
                            }

                            bordered
                            dataSource={this.props.selectedBookingRoles}
                            renderItem={
                                item =>
                                    <List.Item
                                        extra={

                                            <div className="delete-field-button-wrapper">
                                                <CloseCircleOutlined
                                                    checked={false}
                                                    onClick={(e) => { this.props.bookingRolesCheckboxChanged(e, item) }}
                                                />
                                            </div>
                                        }
                                    >
                                        <div className="content-wrapper">{item.name}</div>
                                    </List.Item>
                            }
                        />
                    </div>
                </Col>

                <Col span={14}>
                    <Form.Item name="roleFilter">
                        <Input
                            placeholder='Поиск'
                            prefix={<SearchOutlined />}
                            onChange={(changeEvent) => this.bookingRolesFilterChanged(changeEvent)}
                        />
                    </Form.Item>

                    <div className="list-wrapper">
                        <List
                            size="small"
                            bordered
                            dataSource={this.getFilteredArray(this.props.bookingRoles, this.state.bookingRolesFilter) as BookingRole[]}
                            renderItem={
                                item =>
                                    <List.Item
                                    >
                                        <Checkbox
                                            checked={this.props.selectedBookingRoles.some(selectedRole => selectedRole.id === item.id)}
                                            onChange={(e) => { this.props.bookingRolesCheckboxChanged(e, item) }}
                                        > {
                                                item.name}
                                        </Checkbox>
                                    </List.Item>
                            }
                        />
                    </div>
                </Col>
            </Row>
        )
    }
}