import BookingRoleUseCase from "../../../../../domain/booking/interactors/BookingRoleUseCase";
import { BookingRole, BookingRoleBody } from "../../../../../domain/booking/models/BookingRole";

export default class RolesViewModel {

    bookingRoles: BookingRole[] = [];
    userEmail?: string;

    onDidStartRequest: (() => void) = () => { };
    onDidFinishRequest: (() => void) = () => { };
    onDidUpdate: (() => void) = () => { };
    onDidReceiveError: ((error: Error) => void) = () => { };

    private bookingRoleUseCase: BookingRoleUseCase;

    constructor(bookingRoleUseCase: BookingRoleUseCase) {
        this.bookingRoleUseCase = bookingRoleUseCase;
    }

    getUserEmail() {
        this.onDidStartRequest();
        this.bookingRoleUseCase.getCurrentUser()
            .then(userProfile => {
                this.userEmail = userProfile.email
                this.onDidUpdate();
            })
            .catch(error => {
                this.onDidReceiveError(error);
            }).finally(() => {
                this.onDidFinishRequest();
            });
    }

    getBookingRoles() {
        this.onDidStartRequest();
        this.bookingRoleUseCase.getBookingRoles().then(roles => {
            this.bookingRoles = roles;
            this.onDidUpdate();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    createBookingRole(newBookingRole: BookingRoleBody) {
        this.onDidStartRequest();
        this.bookingRoleUseCase.createBookingRole(newBookingRole).then(() => {
            this.getBookingRoles()
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    deleteBookingRole(bookingRoleId: string) {
        this.onDidStartRequest();
        this.bookingRoleUseCase.deleteBookingRole(bookingRoleId).then(() => {
            this.getBookingRoles()
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }

    updateBookingRole(roleId: string, newBookingRole: BookingRoleBody) {
        this.onDidStartRequest();
        this.bookingRoleUseCase.updateBookingRole(roleId, newBookingRole).then(() => {
            this.getBookingRoles()
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }
}