import React from "react";
import { Layout } from 'antd';
import { ReactComponent as TsuLogo } from "../../resources/images/tsu_logo_small.svg";
import "./main-layout.scss";
import SideMenu from "./side-menu/SideMenu";

const {  Content, Footer, Sider } = Layout;

interface MainLayoutProps {
    children: React.ReactNode
}

interface MainLayoutState {
    collapsed: boolean
}

export default class MainLayout extends React.Component<MainLayoutProps, MainLayoutState> {
    constructor(props: MainLayoutProps) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                    <div className={`logo ${collapsed ? "collapsed" : null}`}>
                        <TsuLogo className="image"/>
                        <div className="title-container">
                            <span className="title">TSU.InTime</span>
                            <span className="subtitle">Администрирование</span>
                        </div>
                    </div>
                    <SideMenu/>
                </Sider>
                <Layout>
                    <Content style={{ margin: '0 16px' }}>
                        {
                            this.props.children
                        }
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
                </Layout>
            </Layout>
        );
    }
}