import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate
} from "react-router-dom";

import Dependencies from "../utils/Dependencies";
import MainLayout from "../scenes/main/MainLayout";
import BookingApplicationsView from "../scenes/booking/applications/views/BookingApplicationsView";
import Login from "../scenes/autorization/login/views/Login";
import UseCaseFactory from "../utils/UseCaseFactory";
import LoginViewModel, { LoginViewModelDelegate } from "../scenes/autorization/login/view-models/LoginViewModel";
import LoginFinish from "../scenes/autorization/login/views/LoginFinish";
import LoginFinishViewModel, {
    LoginFinishViewModelDelegate
} from "../scenes/autorization/login/view-models/LoginFinishViewModel";
import UsersView from "../scenes/users/views/UsersView";
import UsersViewModel from "../scenes/users/view-models/UsersViewModel";
import BookingApplicationsViewModel from "../scenes/booking/applications/view-models/BookingApplicationsViewModel";
import Logout from "../scenes/autorization/logout/views/Logout";
import LogoutViewModel, { LogoutViewModelDelegate } from "../scenes/autorization/logout/view-models/LogoutViewModel";
import AudienceGroupsViewModel from "../scenes/booking/audienceGroups/view-models/AudienceGroupsViewModel";
import AudienceGroupsView from "../scenes/booking/audienceGroups/views/AudienceGroupsView";
import RolesViewModel from "../scenes/booking/roles/view-models/RolesViewModel";
import RolesView from "../scenes/booking/roles/views/RolesView";


interface MainRouterProps { }


interface MainRouterState {
    isAuthorized: boolean
}

export default class MainRouter extends React.Component<MainRouterProps, MainRouterState>
    implements LoginFinishViewModelDelegate, LoginViewModelDelegate, LogoutViewModelDelegate {

    private useCaseFactory = new UseCaseFactory();

    constructor(props: MainRouterProps) {
        super(props);

        this.state = {
            isAuthorized: false
        };
    }

    componentDidMount() {
        if (Dependencies.dataStore.accessToken && Dependencies.dataStore.refreshToken) {
            this.setState({
                isAuthorized: true
            });
        } else {
            this.setState({
                isAuthorized: false
            });
        }
    }

    loginViewModelHasAuthorized() {
        this.setState({
            isAuthorized: true
        });
        window.location.href = "/booking/applications";
    }

    loginFinishViewModelHasAuthorized() {
        this.setState({
            isAuthorized: true
        });
        window.location.href = "/booking/applications";
    }

    loginFinishViewModelHasLogout() {
        this.setState({
            isAuthorized: false
        });
        window.location.href = "/";
    }

    private makeNonAuthorizedRoutes = () => {
        let loginViewModel = new LoginViewModel(this.useCaseFactory.createLoginUseCase());
        loginViewModel.delegate = this;

        let loginFinishViewModel = new LoginFinishViewModel(this.useCaseFactory.createLoginUseCase());
        loginFinishViewModel.delegate = this;

        return (
            <Routes>
                <Route path="/login/finish" element={<LoginFinish viewModel={loginFinishViewModel} />} />
                <Route path="/" element={<Login viewModel={loginViewModel} />} />
            </Routes>
        );
    }

    private makeAuthorizedRoutes = () => {
        let bookingApplicationsViewModel = new BookingApplicationsViewModel(this.useCaseFactory.createBookingUseCase());
        let usersViewModel = new UsersViewModel(this.useCaseFactory.createUsersUseCase(),
            this.useCaseFactory.createBookingRoleUseCase());
        let audienceGroupsViewModel = new AudienceGroupsViewModel(this.useCaseFactory.createBookingAudienceGroupUseCase(),
            this.useCaseFactory.createBookingRoleUseCase());
        let logoutViewModel = new LogoutViewModel(this.useCaseFactory.createLogoutUseCase());
        let rolesViewModel = new RolesViewModel(this.useCaseFactory.createBookingRoleUseCase())
        logoutViewModel.delegate = this;

        return (
            <MainLayout>
                <Routes>
                    <Route path="/booking/applications"
                        element={<BookingApplicationsView viewModel={bookingApplicationsViewModel} />} />
                    <Route path="/booking/audienceGroups"
                        element={<AudienceGroupsView audienceViewModel={audienceGroupsViewModel} />} />
                    <Route path="/booking/roles"
                        element={<RolesView viewModel={rolesViewModel} />} />
                    <Route path="/users" element={<UsersView viewModel={usersViewModel} />} />
                    <Route path="/logout" element={<Logout viewModel={logoutViewModel} />} />
                    <Route path="/" element={<Navigate to="/booking/applications" />} />
                </Routes>
            </MainLayout>
        );
    }

    render() {
        return (
            <Router>
                {
                    this.state.isAuthorized ?
                        this.makeAuthorizedRoutes()
                        : this.makeNonAuthorizedRoutes()
                }
            </Router>
        );
    }
}