import Dependencies from "./Dependencies";
import LoginUseCase from "../../domain/auth/interactors/LoginUseCase";
import UsersUseCase from "../../domain/users/interactors/UsersUseCase";
import BookingUseCase from "../../domain/booking/interactors/BookingUseCase";
import LogoutUseCase from "../../domain/auth/interactors/LogoutUseCase";
import BookingAudienceGroupUseCase from "../../domain/booking/interactors/BookingAudienceGroupUseCase";
import BookingRoleUseCase from "../../domain/booking/interactors/BookingRoleUseCase";

export default class UseCaseFactory {
    createLoginUseCase(): LoginUseCase {
        return new LoginUseCase(Dependencies.authRepository);
    }

    createLogoutUseCase(): LogoutUseCase {
        return new LogoutUseCase(Dependencies.authRepository);
    }

    createUsersUseCase(): UsersUseCase {
        return new UsersUseCase(Dependencies.staffRepository);
    }

    createBookingUseCase(): BookingUseCase {
        return new BookingUseCase(Dependencies.bookingRepository);
    }

    createBookingAudienceGroupUseCase(): BookingAudienceGroupUseCase {
        return new BookingAudienceGroupUseCase(Dependencies.bookingRepository, Dependencies.buildingsRepository);
    }

    createBookingRoleUseCase(): BookingRoleUseCase {
        return new BookingRoleUseCase(Dependencies.bookingRepository);
    }
}