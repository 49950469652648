import React from "react";
import {Card, Layout, Spin} from "antd";
import LoginFinishViewModel from "../view-models/LoginFinishViewModel";
import "./login.scss";
import Notifications from "../../../../utils/Notifications";

interface LoginFinishProps {
    viewModel: LoginFinishViewModel;
}

export default class LoginFinish extends React.Component<LoginFinishProps> {
    private viewModel: LoginFinishViewModel;

    constructor(props: LoginFinishProps) {
        super(props);
        this.viewModel = this.props.viewModel;
    }

    componentDidMount() {
        this.bindToViewModel();
        this.viewModel.finishAuthorization();
    }

    private bindToViewModel() {
        this.viewModel.onDidReceiveError = (error) => {
            Notifications.handle(error);
        };
    }

    render() {
        return (
            <Layout>
                <div className="login-content">
                    <Card className="login-card">
                        <Spin/>
                    </Card>
                </div>
            </Layout>
        );
    }
}