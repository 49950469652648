const LoginStrings = {
    logoTitle: "TSU.InTime",
    logoSubtitle: "Панель администратора",
    emailFieldPlaceholder: "E-mail",
    passwordFieldPlaceholder: "Пароль",
    loginButtonTitle: "Войти",
    buttonDividerText: "или",
    tsuAccountsLoginButtonTitle: "Войти через ТГУ.Аккаунт"
};

export default LoginStrings