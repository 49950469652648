import StaffRepository from "../repository/StaffRepository";
import StaffInfo from "../models/StaffInfo";
import EmptyResponse from "../../../data/network-service/EmptyResponse";
import UserProfile from "../../common/models/UserProfile";

export default class UsersUseCase {
    private staffRepository: StaffRepository;

    constructor(staffRepository: StaffRepository) {
        this.staffRepository = staffRepository;
    }

    getStaff(): Promise<StaffInfo[]> {
        return this.staffRepository.getStaff();
    }

    addBookingAdmin(accountId: string, bookingRoleIds: string[]): Promise<EmptyResponse> {
        return this.staffRepository.addBookingAdmin(accountId, bookingRoleIds);
    }

    removeBookingAdmin(accountId: string): Promise<EmptyResponse> {
        return this.staffRepository.removeBookingAdmin(accountId);
    }

    getModerators(): Promise<UserProfile[]> {
        return this.staffRepository.getModerators();
    }

    addBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
        return this.staffRepository.addBookingRoleForModerator(moderatorId, bookingRoleId);
    }

    removeAllModeratorRoles(moderatorId: string): Promise<EmptyResponse> {
        return this.staffRepository.removeAllModeratorRoles(moderatorId);
    }

    removeBookingRoleForModerator(moderatorId: string, bookingRoleId: string): Promise<EmptyResponse> {
        return this.staffRepository.removeBookingRoleForModerator(moderatorId, bookingRoleId);
    }
}