import React from "react";
import { Avatar, Button, Card, Descriptions, Empty, Input, Spin, Table, Tag, Typography, Modal, Alert } from "antd";
import BookingApplicationsViewModel from "../view-models/BookingApplicationsViewModel";
import "./booking-applications.scss";
import BookingApplication, { BookingApplicationStatus } from "../../../../../domain/booking/models/BookingApplication";
import moment from "moment";
import 'moment/locale/ru';
import Notifications from "../../../../utils/Notifications";
import UserProfile from "../../../../../domain/common/models/UserProfile";

const { Title, Paragraph } = Typography;

interface StatusInfo {
    name: string;
    color: string
}

const statusInfos: Record<BookingApplicationStatus, StatusInfo> = {
    [BookingApplicationStatus.new]: { name: "Новый", color: "blue" },
    [BookingApplicationStatus.approved]: { name: "Одобрено", color: "green" },
    [BookingApplicationStatus.rejected]: { name: "Отклонено", color: "red" },
    [BookingApplicationStatus.canceled]: { name: "Отменено", color: "volcano" },
    [BookingApplicationStatus.requireApprove]: { name: "Требуется согласование", color: "orange" }
}

const shortDateFormat = "DD.MM.YYYY";
const fullDateFormat = "D MMMM YYYY";
const momentLocale = "ru";

interface BookingApplicationsProps {
    viewModel: BookingApplicationsViewModel
}

interface BookingApplicationsViewState {
    applications: BookingApplication[];
    filteredApplications: BookingApplication[];
    isLoading: boolean;
    selectedApplicationID?: string;
    isCancelModalShown: boolean;
    cancelApplicationReasonText: string;
    currentUser?: UserProfile;
}

export default class BookingApplicationsView extends React.Component<BookingApplicationsProps, BookingApplicationsViewState> {
    private viewModel: BookingApplicationsViewModel;

    constructor(props: BookingApplicationsProps) {
        super(props);
        this.state = {
            applications: [],
            filteredApplications: [],
            isLoading: false,
            isCancelModalShown: false,
            cancelApplicationReasonText: ""
        };
        this.viewModel = props.viewModel;
    }

    componentDidMount() {
        moment.locale(momentLocale);
        this.bindToViewModel();
        this.viewModel.loadData();
    }

    private bindToViewModel() {
        this.viewModel.onDidStartRequest = () => {
            this.setState({
                isLoading: true
            });
        };
        this.viewModel.onDidFinishRequest = () => {
            this.setState({
                isLoading: false
            });
        };
        this.viewModel.onDidUpdate = () => {
            this.setState({
                applications: this.viewModel.applications,
                filteredApplications: this.viewModel.applications,
                currentUser: this.viewModel.currentUser
            });
        };
        this.viewModel.onDidReceiveError = (error) => {
            this.setState({
                isLoading: false
            })
            Notifications.handle(error);
        };
    }

    getApproveRejectButtons(selectedApplication: BookingApplication) {

        const isApplicationApprovedByRoleOfUser = this.viewModel.isApplicationApprovedByRoleOfUser(selectedApplication)

        if (isApplicationApprovedByRoleOfUser === 'APPROVED') {
            return <Button danger onClick={() => {
                this.setState({
                    isCancelModalShown: true,
                    cancelApplicationReasonText: ""
                });
            }}>Отклонить</Button>
        }
        else if (isApplicationApprovedByRoleOfUser === 'NOT_APPROVED') {
            return <>
                <Button type="primary" onClick={() => {
                    if (selectedApplication) {
                        this.viewModel.approveBooking(selectedApplication?.id)
                    }
                }}>Одобрить</Button>

                <Button danger onClick={() => {
                    this.setState({
                        isCancelModalShown: true,
                        cancelApplicationReasonText: ""
                    });
                }}>Отклонить</Button>
            </>
        }

        return null
    }

    render() {
        let selectedApplication: BookingApplication | undefined = undefined;
        if (this.state.selectedApplicationID) {
            selectedApplication = this.state.applications.find(item => item.id === this.state.selectedApplicationID);
        }

        return (
            <div className="booking-applications-page-container">
                <Title>Заявки</Title>
                <Card className="booking-applications-content-container">
                    {/* @ts-ignore */}
                    <Spin tip="Загрузка..." spinning={this.state.isLoading}>
                        <Input.Search className="booking-applications-search-input"
                            placeholder="Название"
                            onSearch={(value: string) => {
                                this.setState({
                                    filteredApplications: this.state.applications.filter((record: BookingApplication) => {
                                        return record.title.toLowerCase().indexOf(value.toLowerCase()) >= 0
                                    })
                                });
                            }}
                            enterButton />
                        <div className="booking-applications-master-detail-container">
                            <Table className="booking-applications-list"
                                rowClassName="booking-applications-list-row"
                                bordered={true}
                                dataSource={this.state.filteredApplications}
                                rowKey={record => record.id}
                                pagination={{
                                    size: "small"
                                }}>
                                <Table.Column
                                    dataIndex="title"
                                    onFilter={(value: (string | number | boolean), record: BookingApplication) => record.status === value}
                                    sorter={(a: BookingApplication, b: BookingApplication) => moment(a.date).unix() - moment(b.date).unix()}
                                    filters={[
                                        {
                                            text: statusInfos[BookingApplicationStatus.new].name,
                                            value: BookingApplicationStatus.new
                                        },
                                        {
                                            text: statusInfos[BookingApplicationStatus.approved].name,
                                            value: BookingApplicationStatus.approved
                                        },
                                        {
                                            text: statusInfos[BookingApplicationStatus.rejected].name,
                                            value: BookingApplicationStatus.rejected
                                        },
                                        {
                                            text: statusInfos[BookingApplicationStatus.canceled].name,
                                            value: BookingApplicationStatus.canceled
                                        },
                                        {
                                            text: statusInfos[BookingApplicationStatus.requireApprove].name,
                                            value: BookingApplicationStatus.requireApprove
                                        }
                                    ]}
                                    render={(text, record: BookingApplication) => {
                                        return (
                                            <div className="booking-applications-list-row-content"
                                                onClick={() => {
                                                    this.setState({
                                                        selectedApplicationID: record.id
                                                    })
                                                }}>
                                                <Paragraph ellipsis={{
                                                    rows: 1,
                                                }} className="title">{record.title}</Paragraph>
                                                <div className="info-container">
                                                    <span className="date">
                                                        {
                                                            moment(record.date).format(shortDateFormat)
                                                        }
                                                    </span>
                                                    <Tag color={statusInfos[record.status].color}>
                                                        {statusInfos[record.status].name}
                                                    </Tag>
                                                </div>
                                            </div>
                                        );
                                    }} />
                            </Table>
                            <Card className="booking-applications-details"
                                title={selectedApplication?.title}>
                                {
                                    selectedApplication ?
                                        <>
                                            <div className="booking-applications-top-container">
                                                <div className="user-info">
                                                    <Avatar size={48}
                                                        className="avatar"
                                                        src={selectedApplication.user.tsuAccountProfile.avatarUrl} />
                                                    <div className="user-description-container">
                                                        <span className="user-name">
                                                            {selectedApplication.user.tsuAccountProfile.lastName + " "
                                                                + selectedApplication.user.tsuAccountProfile.firstName + " "
                                                                + selectedApplication.user.tsuAccountProfile.secondName}
                                                        </span>
                                                        <a href={`mailto://${selectedApplication.user.email}`}>
                                                            {selectedApplication.user.email}
                                                        </a>
                                                    </div>
                                                </div>
                                                <Tag color={statusInfos[selectedApplication.status].color}>
                                                    {statusInfos[selectedApplication.status].name}
                                                </Tag>
                                            </div>

                                            <Descriptions layout="vertical" labelStyle={{ fontWeight: "bold" }}>
                                                <Descriptions.Item label="Дата">
                                                    {
                                                        moment(selectedApplication.date).format(fullDateFormat)
                                                    }
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Кол-во участников">
                                                    {selectedApplication.participantCount}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Пары">
                                                    {
                                                        selectedApplication.lessons.map(lesson => {
                                                            let startTime = moment(lesson.start * 1000).format("HH:mm");
                                                            let endTime = moment(lesson.end * 1000).format("HH:mm");
                                                            return (
                                                                <>
                                                                    {`${lesson.lessonNumber}-я пара (${startTime}–${endTime})`}<br />
                                                                </>);
                                                        })
                                                    }
                                                </Descriptions.Item>


                                                <Descriptions.Item label="Аудитория" span={3}>
                                                    {
                                                        selectedApplication.audience.name
                                                        + (selectedApplication.audience.building
                                                            ? ` (${selectedApplication.audience.building?.name})` : null)
                                                    }
                                                </Descriptions.Item>


                                                {selectedApplication.additionalFields.map(field => {
                                                    return (
                                                        <Descriptions.Item label={field.field.title} span={3}>
                                                            {field.value}
                                                        </Descriptions.Item>)
                                                })
                                                }

                                                {
                                                    selectedApplication.status === BookingApplicationStatus.requireApprove &&
                                                    <Descriptions.Item label="Требуется согласование">
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            {
                                                                selectedApplication.requiredBookingRoles.filter(req => !selectedApplication?.approvedByRoles.some(approved => approved.id === req.id)).map(role => {
                                                                    return <div>{role.name}</div>
                                                                })
                                                            }
                                                        </div>
                                                    </Descriptions.Item>
                                                }

                                            </Descriptions>
                                            {
                                                ![
                                                    BookingApplicationStatus.canceled,
                                                    BookingApplicationStatus.rejected
                                                ].includes(selectedApplication.status) ?
                                                    <div className="actions-container">
                                                        <>
                                                            {this.getApproveRejectButtons(selectedApplication)}

                                                            {/* @ts-ignore */}
                                                            <Modal title="Отклонить заявку"
                                                                visible={this.state.isCancelModalShown}
                                                                okButtonProps={{
                                                                    danger: true,
                                                                    disabled: this.state.cancelApplicationReasonText.length === 0
                                                                }}
                                                                okText="Отклонить"
                                                                onCancel={() => {
                                                                    this.setState({
                                                                        isCancelModalShown: false
                                                                    });
                                                                }}
                                                                onOk={() => {
                                                                    if (selectedApplication) {
                                                                        this.viewModel.rejectBooking(selectedApplication?.id,
                                                                            this.state.cancelApplicationReasonText)
                                                                    }
                                                                    this.setState({
                                                                        cancelApplicationReasonText: "",
                                                                        isCancelModalShown: false
                                                                    })
                                                                }}>

                                                                <Alert
                                                                    style={{ textAlign: 'center', marginBottom: '16px' }}
                                                                    description="Данное действие отменяет заявку независимо от наличия одобрения от других отделов и не может быть отменено."
                                                                    type="error"
                                                                />

                                                                <Input.TextArea
                                                                    value={this.state.cancelApplicationReasonText}
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            cancelApplicationReasonText: event.target.value
                                                                        })
                                                                    }}
                                                                    rows={4}
                                                                    placeholder="Укажите причину" />
                                                            </Modal>
                                                        </>
                                                    </div> : null
                                            }
                                        </>
                                        : <Empty />
                                }
                            </Card>
                        </div>
                    </Spin>
                </Card>
            </div>
        );
    }
}