import LoginUseCase from "../../../../../domain/auth/interactors/LoginUseCase";

export interface LoginViewModelDelegate {
    loginViewModelHasAuthorized(): void
}

export default class LoginViewModel {
    delegate?: LoginViewModelDelegate

    onDidStartRequest: (() => void) = () => {};
    onDidFinishRequest: (() => void) = () => {};
    onDidReceiveError: ((error: Error) => void) = () => {};

    private loginUseCase: LoginUseCase

    constructor(loginUseCase: LoginUseCase) {
        this.loginUseCase = loginUseCase
    }

    loginWithEmail(email: string, password: string) {
        this.onDidStartRequest();
        this.loginUseCase.loginWithEmail(email, password).then(response => {
            this.delegate?.loginViewModelHasAuthorized();
        }).catch(error => {
            this.onDidReceiveError(error);
        }).finally(() => {
            this.onDidFinishRequest();
        });
    }
}